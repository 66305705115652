import { addOperation, clearOperations } from "actions/operationsActions";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import type { Operation } from "constants/AppConstants";
import store from "store";
import AppsmithConsole, { getTimeStamp } from "./AppsmithConsole";
import { isEqual, uniqWith } from "lodash";
import log from "loglevel";

function dispatchAction(action: ReduxAction<unknown>) {
  store.dispatch(action);
}

// 清空“操作栈”
function clear() {
  dispatchAction(clearOperations());
}

// 操作栈中，新增记录
function add(logs: Operation[]) {
  const oplogs = uniqWith(logs, isEqual);
  dispatchAction(addOperation(oplogs));
  log.info("\n\n\t\t##### operation stack push #####");
  log.info("\t\t##### operation", oplogs);
  log.info("\t\t##### operation stack push #####\n\n");
}

export default {
  add,
  clear,
  getTimeStamp,
};
