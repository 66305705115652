export * from "ce/api/UserApi";
import { UserApi as CE_UserApi } from "ce/api/UserApi";
import type { AxiosPromise, AxiosResponse } from "axios";
import Api from "api/Api";
import type { ApiResponse } from "api/ApiResponses";

export interface UserType {
  id: string;
  name: string;
  username: string;
  applicationRoles: any[];
  groups: any[];
}

export interface CurrentUserSystemRolesType {
  id: string;
  name: string;
  type: string;
  description: string;
  permissions: any[];
  isCurrent?: boolean;
}

export type FetchUserResponse = ApiResponse & {
  email: string;
  id: string;
};

class UserApi extends CE_UserApi {
  static userManageURL = `${UserApi.usersURL}/manage`;
  static systemRolesURL = `${UserApi.userSystemRolesURL}/roles`;
  static currentUserSystemRolesURL = `${UserApi.userURL}`;
  static captchaURL = "v1/captcha";
  static async fetchUsers(): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.get(`${UserApi.userManageURL}/all`);
  }

  static async fetchUserDetail(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.get(`${UserApi.userManageURL}/${id}`);
  }

  static async deleteUser(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.delete(`${UserApi.userManageURL}/${id}`);
  }

  static async fetchCaptcha(): Promise<AxiosResponse<any, any>> {
    return Api.get(UserApi.captchaURL, {}, { responseType: "blob" });
  }

  static async fetchAllUserSystemRoles(): Promise<
    AxiosResponse<ApiResponse<unknown>, any>
  > {
    return Api.get(`${UserApi.systemRolesURL}`);
  }

  static async fetchCurrentUserSystemRoles(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.get(`${UserApi.currentUserSystemRolesURL}/${id}/system/roles`);
  }
}

export default UserApi;
