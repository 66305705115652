import React, { useState, useEffect, useRef, useMemo } from "react";
import type {
  ApplicationPayload,
  Page,
} from "ee/constants/ReduxActionConstants";
import {
  getAppSidebarPinned,
  getCurrentApplication,
  getAppMode,
} from "ee/selectors/applicationSelectors";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PrimaryCTA from "./PrimaryCTA";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import {
  getCurrentPageId,
  previewModeSelector,
} from "selectors/editorSelectors";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import { getAppViewHeaderHeight } from "selectors/appViewSelectors";
import { useOnClickOutside } from "utils/hooks/useOnClickOutside";
import { useHref } from "pages/Editor/utils";
import { APP_MODE } from "entities/App";
import { builderURL, viewerURL } from "ee/RouteBuilder";
import { trimQueryString } from "utils/helpers";
import _, { get as _get } from "lodash";
import type { NavigationSetting } from "constants/AppConstants";
import { NAVIGATION_SETTINGS } from "constants/AppConstants";
import { PageMenuContainer, StyledNavLink } from "./PageMenu.styled";
import ShareButton from "./Navigation/components/ShareButton";
import BackToAppsButton from "./Navigation/components/BackToAppsButton";
import { View } from "@tarojs/components";
import { getCurrentThemeDetails } from "selectors/themeSelectors";
import { getIsAppSettingsPaneWithNavigationTabOpen } from "selectors/appSettingsPaneSelectors";
// import ApplicationLogo from "./Navigation/components/ApplicationLogo";
import history from "utils/history";
import { ConfigProvider, Menu } from "antd";
import { filterHiddenTreeData, mapClearTree } from "utils/treeUtils";
import { Icon } from "design-system";
// import SidebarProfileComponent from "./Navigation/components/SidebarProfileComponent";
import { makeRouteNode } from "./utils";
import styled from "styled-components";
import {
  StyledCtaContainer,
  StyledMenuContainer,
  StyledSidebar,
} from "./Navigation/Sidebar.styled";
import { getPath, getMenuItemTextColor } from "./utils";
import { getAppsmithConfigs } from "ee/configs";
import { renderIcon } from "components/common/AntdIcon";
interface NavigationProps {
  isOpen?: boolean;
  application?: ApplicationPayload;
  pages: Page[];
  url?: string;
  setMenuOpen?: (shouldOpen: boolean) => void;
  headerRef?: React.RefObject<HTMLDivElement>;
}

const MyMenu = styled(Menu)<{
  theme: string;
  primaryColor: string;
  navColorStyle: any;
}>`
  color: "rgba(0,0,0,0.65)";
`;

export function PageMenu(props: NavigationProps) {
  const { application, headerRef, isOpen, pages, setMenuOpen } = props;
  const menuRef = useRef<any>();
  const currentApplicationDetails = application;
  const selectedTheme = useSelector(getSelectedAppTheme);
  const appMode = useSelector(getAppMode);
  const workspaceID = useSelector(getCurrentWorkspaceId);
  const headerHeight = useSelector(getAppViewHeaderHeight);
  const isPinned = useSelector(getAppSidebarPinned);
  const theme = useSelector(getCurrentThemeDetails);
  const isPreviewMode = useSelector(previewModeSelector);
  const isAppSettingsPaneWithNavigationTabOpen = useSelector(
    getIsAppSettingsPaneWithNavigationTabOpen,
  );
  const [query, setQuery] = useState("");
  const { inCloudOS } = getAppsmithConfigs();

  const logoConfiguration =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.logoConfiguration ||
    NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE;
  const isMinimal =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.navStyle === NAVIGATION_SETTINGS.NAV_STYLE.MINIMAL;
  const navColorStyle =
    application?.applicationDetail?.navigationSetting?.colorStyle ||
    NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT;
  const navStyle =
    currentApplicationDetails?.applicationDetail?.navigationSetting?.navStyle ||
    NAVIGATION_SETTINGS.NAV_STYLE.STACKED;
  const primaryColor = _.get(
    selectedTheme,
    "properties.colors.primaryColor",
    "inherit",
  );
  const currentApp = useSelector(getCurrentApplication);

  const viewerLayout = currentApp?.viewerLayout;
  const pagesMap = pages.reduce((a: any, c: any) => {
    a[c.pageId] = { ...c };
    return a;
  }, {});

  const closeMenu = () => {
    if (typeof setMenuOpen === "function") {
      setMenuOpen?.(false);
    }
  };

  // hide menu on click outside
  useOnClickOutside(
    [menuRef, headerRef as React.RefObject<HTMLDivElement>],
    () => {
      closeMenu();
    },
  );

  const initState = useMemo(() => {
    let menudata: any = [];
    if (viewerLayout && pages.length) {
      try {
        const current = JSON.parse(viewerLayout);
        const _outsiderTree = current.outsiderTree || [];
        menudata = current?.treeData
          .map((itdata: any) => {
            return mapClearTree(itdata, (item: any) => {
              const title = _get(
                pagesMap,
                [item.pageId, "pageName"],
                item.title,
              ); //用最新的 pageName
              if (_outsiderTree.find((n: any) => n.pageId === item.pageId)) {
                return false;
              }
              if (item.title && item.isPage && !pagesMap[item.pageId]) {
                return false;
              }
              return {
                ...item,
                key: item.pageId || item.key,
                children: _.size(item.children) ? item.children : null,
                label: title,
                icon: renderIcon(item.icon),
              };
            });
          })
          .filter((i: any) => i);
      } catch (e) {
        console.log(e);
      }
    } else {
      menudata = pages.map((p) => {
        return {
          title: p.pageName,
          pageId: p.pageId,
          isPage: true,
          key: p.pageId,
          label: p.pageName,
          children: null,
        };
      });
    }
    return {
      menudata,
    };
  }, [viewerLayout, pages, currentApplicationDetails]);

  useEffect(() => {
    setQuery(window.location.search);
  }, [location.search]);

  const clickMenu = (item: any) => {
    const target_path = getPath(
      { pageId: item.pageId },
      pagesMap,
      item.pageId,
      appMode,
    );
    if (target_path) {
      history.push(target_path);
    }
    closeMenu();
  };

  const calculateSidebarHeight = () => {
    let prefix = `calc( 100vh - `;
    const suffix = ")";
    if (isPreviewMode) {
      prefix += `${theme.smallHeaderHeight} - ${theme.bottomBarHeight}`;
    } else if (isAppSettingsPaneWithNavigationTabOpen) {
      prefix += `${theme.smallHeaderHeight} - ${theme.bottomBarHeight} - 66px`;
    } else {
      prefix += "48px";
    }

    return prefix + suffix;
  };

  const current_theme =
    _.get(
      currentApplicationDetails,
      ["applicationDetail", "navigationSetting", "colorStyle"],
      "theme",
    ) === "theme"
      ? "dark"
      : "light";
  return (
    <>
      {/* BG OVERLAY */}
      <div
        className={classNames({
          "fixed h-full w-full bg-black/30 transform transition-all": true,
          "opacity-0 hidden": !isOpen,
          "opacity-100": isOpen,
        })}
        onClick={closeMenu}
        style={{
          height: `calc(100% - ${headerHeight})`,
        }}
      />
      {/* MAIN CONTAINER */}
      <PageMenuContainer
        className={classNames({
          "fixed flex flex-col w-7/12 transform transition-all duration-200":
            true,
          "-left-full": !isOpen,
          "left-0": isOpen,
        })}
        navColorStyle={navColorStyle}
        primaryColor={primaryColor}
        ref={menuRef}
        style={{
          height: `calc(100% - ${headerHeight}px)`,
        }}
      >
        <StyledSidebar
          className={classNames({
            "t--app-viewer-navigation-sidebar": true,
            "is-open": isOpen,
            "shadow-xl": !isPinned,
          })}
          isMinimal={isMinimal}
          navColorStyle={navColorStyle}
          primaryColor={primaryColor}
          sidebarHeight={calculateSidebarHeight()}
        >
          <StyledMenuContainer
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: primaryColor,
                },
                components: {
                  Menu: {
                    darkSubMenuItemBg: "white",
                    darkItemHoverColor: getMenuItemTextColor(
                      primaryColor,
                      navColorStyle,
                    ),
                  },
                },
              }}
            >
              <MyMenu
                className="rootSideMenu pp-menu"
                defaultSelectedKeys={_.get(_.head(initState.menudata), "key")}
                expandIcon={(props: any) => {
                  return props.isOpen ? (
                    <Icon name="arrow-down-s-line" size={"md"} />
                  ) : (
                    <Icon name="arrow-right-s-line" size={"md"} />
                  );
                }}
                inlineCollapsed={false}
                items={filterHiddenTreeData(initState.menudata)}
                mode="inline"
                navColorStyle={navColorStyle}
                onClick={({ item }) => {
                  clickMenu(item.props);
                }}
                primaryColor={primaryColor}
                theme={current_theme}
              />
            </ConfigProvider>
          </StyledMenuContainer>
          {/* {props.showUserSettings && !inCloudOS && (
            <StyledFooter
              navColorStyle={navColorStyle}
              primaryColor={primaryColor}
            >
              <SidebarProfileComponent
                currentUser={currentUser}
                isInline
                isMinimal={isMinimal}
                isOpen={isOpen}
                navColorStyle={navColorStyle}
                primaryColor={primaryColor}
              />
            </StyledFooter>
          )} */}
          <div className="py-3 border-t">
            {application && (
              <StyledCtaContainer>
                <ShareButton
                  currentApplicationDetails={application}
                  currentWorkspaceId={workspaceID}
                  insideSidebar
                />

                {isOpen && !inCloudOS && (
                  <PrimaryCTA
                    className="t--back-to-editor--mobile"
                    insideSidebar
                    navColorStyle={navColorStyle}
                    primaryColor={primaryColor}
                    url={props.url}
                  />
                )}
                {!inCloudOS && (
                  <BackToAppsButton
                    currentApplicationDetails={application}
                    insideSidebar
                  />
                )}
              </StyledCtaContainer>
            )}
          </div>
        </StyledSidebar>
      </PageMenuContainer>
    </>
  );
}

function PageNavLink({
  closeMenu,
  navColorStyle,
  page,
  primaryColor,
  query,
}: {
  page: Page;
  query: string;
  closeMenu: () => void;
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
}) {
  const appMode = useSelector(getAppMode);
  const selectedTheme = useSelector(getSelectedAppTheme);
  const pathname = useHref(
    appMode === APP_MODE.PUBLISHED ? viewerURL : builderURL,
    { pageId: page.pageId },
  );

  return (
    <StyledNavLink
      activeClassName="border-r-3 font-semibold"
      activeStyle={{
        borderColor: selectedTheme.properties.colors.primaryColor,
      }}
      className="flex flex-col px-4 py-2 no-underline border-transparent border-r-3 hover:no-underline"
      key={page.pageId}
      navColorStyle={navColorStyle}
      onClick={closeMenu}
      primaryColor={primaryColor}
      to={{
        pathname: trimQueryString(pathname),
        search: query,
      }}
    >
      {page.pageName}
    </StyledNavLink>
  );
}

export default PageMenu;
