import { NAVIGATION_SETTINGS, SIDEBAR_WIDTH } from "constants/AppConstants";
import type { NavigationSetting } from "constants/AppConstants";
import styled from "styled-components";
import {
  getMenuContainerBackgroundColor,
  getMenuItemBackgroundColorOnHover,
  getMenuItemBackgroundColorWhenActive,
  getMenuItemTextColor,
  getSecondMenuItemBackgroundColorOnHover,
  getSecondaryMenuContainerBackgroundColor,
  getBorderColor,
} from "../utils";
import { StyledMenuItem } from "./components/MenuItem.styled";

export const StyledSidebar = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  isMinimal: boolean;
  sidebarHeight: string;
  isStack?: boolean;
}>`
  height: ${({ sidebarHeight }) => sidebarHeight};
  position: fixed;
  top: 0;
  transition: width 0.2s ease 0s;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--ads-v2-color-border);
  width: ${SIDEBAR_WIDTH.MINIMAL}px;
  background-color: ${({ navColorStyle, primaryColor }) =>
    getMenuContainerBackgroundColor(primaryColor, navColorStyle)};
  ${({ isMinimal }) => {
    const width = isMinimal ? SIDEBAR_WIDTH.MINIMAL : SIDEBAR_WIDTH.REGULAR;

    return `
      left: 0px;
    `;
  }}

  .menu-logo {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0px;
  }
  &.is-open {
    left: 0;
    width: ${SIDEBAR_WIDTH.REGULAR}px;
  }

  .ant-menu-dark > .ant-menu,
  .ant-menu-dark {
    background-color: ${({ navColorStyle, primaryColor }) =>
      getMenuContainerBackgroundColor(primaryColor, navColorStyle)} !important;
  }
  .ant-menu-inline-collapsed {
    width: ${SIDEBAR_WIDTH.MINIMAL - 1}px;
  }
  .ant-menu {
    border-inline-end: none !important;
  }
  ${({ navColorStyle, primaryColor, theme }) => `
  .ant-menu-item-selected, 
  .ant-menu .ant-menu-item-selected,
  .ant-menu-inline-collapsed > .ant-menu-submenu-selected {
    background-color:  ${getSecondMenuItemBackgroundColorOnHover(
      primaryColor,
      navColorStyle,
    )} !important;
    color: ${getMenuItemTextColor(
      primaryColor,
      navColorStyle,
      false,
      "active",
    )} !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected):active, 
  .ant-menu-submenu-title:active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color:  ${getSecondMenuItemBackgroundColorOnHover(
      primaryColor,
      navColorStyle,
    )} !important;
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${getMenuItemTextColor(
      primaryColor,
      navColorStyle,
      false,
      "active",
    )} !important;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, 
  .ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color:  ${getSecondaryMenuContainerBackgroundColor(
      primaryColor,
      navColorStyle,
    )} !important;
  }
  .ant-menu-item, .ant-menu-submenu-title, .ant-menu-submenu,.ant-menu-submenu-inline .ant-menu-submenu-open,.ant-menu-submenu-selected {
    border-radius: 2px !important;
  }
`}
`;

export const StyledImage = styled.img`
  max-height: 2.4rem;
`;

export const StyledStackedSidebar = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  isMinimal: boolean;
  sidebarHeight: string;
  isSubMenuOpen?: boolean;
  isOpen?: boolean;
}>`
  height: ${({ sidebarHeight }) => sidebarHeight};
  position: fixed;
  top: 0;

  .ant-menu {
    border-inline-end: none !important;
  }

  .first-menu {
    color: ${({ navColorStyle, primaryColor }) =>
      getMenuItemTextColor(primaryColor, navColorStyle, true)};
    background-color: ${({ navColorStyle, primaryColor }) =>
      getMenuContainerBackgroundColor(primaryColor, navColorStyle)};
    position: fixed;
    top: 0;
    left: 0px;
    width: ${SIDEBAR_WIDTH.MINIMAL}px;
    .menu-part {
      overflow: auto;
      height: calc(100% - 95px);
      font-weight: 500;
    }
    .menu-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .menu-line1 {
      -webkit-line-clamp: 1;
    }
    .menu-line2 {
      -webkit-line-clamp: 2;
    }
    .menu-item {
      min-height: 48px;
      padding: 6px 0px;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      &:hover {
        background-color: ${({ navColorStyle, primaryColor }) =>
          getSecondaryMenuContainerBackgroundColor(
            primaryColor,
            navColorStyle,
          )};
      }
    }
  }
  .menu-logo {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0px;
  }

  .first-active-menuitem {
    background-color: ${({ navColorStyle, primaryColor }) =>
      getSecondaryMenuContainerBackgroundColor(primaryColor, navColorStyle)};
    color: ${({ navColorStyle, primaryColor }) =>
      getMenuItemTextColor(primaryColor, navColorStyle, false, "active")};
  }

  .second-menu {
    width: ${({ isSubMenuOpen }) =>
      isSubMenuOpen ? `${SIDEBAR_WIDTH.INLINEREGULAR}px` : "0px"};
    position: fixed;
    top: 0;
    left: ${SIDEBAR_WIDTH.MINIMAL}px;
    transition: width 0.05s;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    border-right: 1px solid rgba(214, 214, 214, 0.45);
    background: ${({ navColorStyle, primaryColor }) =>
      getMenuContainerBackgroundColor(primaryColor, navColorStyle)};
    color: ${({ navColorStyle, primaryColor }) =>
      getMenuItemTextColor(primaryColor, navColorStyle, true)};
    a {
      transition: color 0.05s;
    }
  }

  ${({ isMinimal }) => {
    const width = isMinimal
      ? SIDEBAR_WIDTH.MINIMAL
      : SIDEBAR_WIDTH.INLINEREGULAR;

    return `
      width: ${width}px;
      left: -${width - SIDEBAR_WIDTH.MINIMAL}px;
    `;
  }}

  &.is-open {
    .second-menu {
      transition: width 0.05s;
      width: ${({ isSubMenuOpen }) =>
        isSubMenuOpen ? `${SIDEBAR_WIDTH.INLINEREGULAR}px` : "0px"};
    }
  }
  ${({ navColorStyle, primaryColor }) => `
  .ant-menu .ant-menu-item-selected {
    background-color:  ${getSecondMenuItemBackgroundColorOnHover(
      primaryColor,
      navColorStyle,
    )} !important;
    color: ${getMenuItemTextColor(
      primaryColor,
      navColorStyle,
      false,
      "active",
    )} !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected):active, 
  .ant-menu-submenu-title:active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color:  ${getSecondMenuItemBackgroundColorOnHover(
      primaryColor,
      navColorStyle,
    )} !important;
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${getMenuItemTextColor(
      primaryColor,
      navColorStyle,
      false,
      "active"
    )} !important;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, 
  .ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color:  ${getSecondaryMenuContainerBackgroundColor(
      primaryColor,
      navColorStyle,
    )} !important;
  }
  .ant-menu-item, 
  .ant-menu-submenu-title, 
  .ant-menu-submenu,
  .ant-menu-submenu-inline .ant-menu-submenu-open,
  .ant-menu-submenu-selected {
    border-radius: 2px !important;
  }
  .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title, 
  .ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title, 
  .ant-menu-inline .ant-menu-submenu-title, 
  .ant-menu-vertical .ant-menu-submenu-title {
    padding-inline-end: 16px !important;
  }
`}
`;

export const StyledStackedMenuContainer = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  isStack?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 12px;
  background-color: ${({ navColorStyle, primaryColor }) =>
    getSecondaryMenuContainerBackgroundColor(primaryColor, navColorStyle)};

  ${({ navColorStyle, primaryColor }) => {
    const isThemeColorStyle: boolean =
      navColorStyle === NAVIGATION_SETTINGS.COLOR_STYLE.THEME;

    return (
      isThemeColorStyle &&
      `
        &::-webkit-scrollbar {
          width: 6px;
        }
      
        &::-webkit-scrollbar-track {
          background: ${getMenuContainerBackgroundColor(
            primaryColor,
            navColorStyle,
          )};
        }
      
        &::-webkit-scrollbar-thumb {
          background: ${primaryColor};
      
          &:hover {
            background: ${getMenuItemBackgroundColorOnHover(
              primaryColor,
              navColorStyle,
            )};
          }
        }

        &:hover::-webkit-scrollbar-thumb {
          background: ${getMenuItemBackgroundColorWhenActive(
            primaryColor,
            navColorStyle,
          )};
        }
      `
    );
  }}

  ${StyledMenuItem} {
    align-self: flex-start;
    width: 80%;
    max-width: initial;
    padding: 8px 10px;
  }
`;

export const StyledMenuContainer = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  isStack?: boolean;
}>`
  overflow-y: auto;
  overflow-x: hidden;
  ${({ navColorStyle, primaryColor }) => {
    const isThemeColorStyle: boolean =
      navColorStyle === NAVIGATION_SETTINGS.COLOR_STYLE.THEME;

    return (
      isThemeColorStyle &&
      `
        &::-webkit-scrollbar {
          width: 6px;
        }
      
        &::-webkit-scrollbar-track {
          background: ${getMenuContainerBackgroundColor(
            primaryColor,
            navColorStyle,
          )};
        }
      
        &::-webkit-scrollbar-thumb {
          background: ${primaryColor};
      
          &:hover {
            background: ${getMenuItemBackgroundColorOnHover(
              primaryColor,
              navColorStyle,
            )};
          }
        }

        &:hover::-webkit-scrollbar-thumb {
          background: ${getMenuItemBackgroundColorWhenActive(
            primaryColor,
            navColorStyle,
          )};
        }
      `
    );
  }}

  ${StyledMenuItem} {
    align-self: flex-start;
    width: 80%;
    max-width: initial;
    padding: 8px 10px;
  }
`;

export const StyledCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 8px;
`;

export const StyledHeader = styled.div`
  padding: 16px 8px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledStackFooter = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
}>`
  margin-top: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: ${({ navColorStyle, primaryColor }) =>
    getMenuContainerBackgroundColor(primaryColor, navColorStyle)};

  ${({ navColorStyle, primaryColor }) => `
    border-top: 1px solid ${getBorderColor(primaryColor, navColorStyle)};
  `}
`;

export const StyledFooter = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
}>`
  margin-top: auto;
  padding-bottom: 4px;
  padding-top: 4px;

  ${({ navColorStyle, primaryColor }) => `
    border-top: 1px solid ${getSecondaryMenuContainerBackgroundColor(
      primaryColor,
      navColorStyle,
    )};
  `}
`;
