/* eslint-disable @typescript-eslint/no-restricted-imports */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import type { InjectedFormProps } from "redux-form";
import { change, reduxForm, formValueSelector } from "redux-form";
import StyledForm from "components/editorComponents/Form";
import { FormActions, FormMessagesContainer } from "./StyledComponents";
import {
  ALREADY_HAVE_AN_ACCOUNT,
  SIGNUP_PAGE_LOGIN_LINK_TEXT,
  FORGOT_PASSWORD_PAGE_EMAIL_INPUT_PLACEHOLDER,
  FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON_TEXT,
  FORGOT_PASSWORD_PAGE_TITLE,
  FORM_VALIDATION_EMPTY_EMAIL,
  FORM_VALIDATION_INVALID_EMAIL,
  FORGOT_PASSWORD_PAGE_LOGIN_LINK,
  createMessage,
  LOGIN_PAGE_CAPTCHA_INPUT_PLACEHOLDER,
  LOGIN_PAGE_REFRESH_CAPTCHA_TEXT,
} from "ee/constants/messages";
import { AUTH_LOGIN_URL } from "constants/routes";
import {
  FORGOT_PASSWORD_FORM_NAME,
  LOGIN_FORM_CAPTCHA_FIELD_NAME,
} from "ee/constants/forms";
import FormTextField from "components/utils/ReduxFormTextField";
import { FormGroup } from "design-system-old";
import { Button, Link, Callout } from "design-system";
import { isEmail, isEmptyString } from "utils/formhelpers";
import type { ForgotPasswordFormValues } from "./helpers";
import { forgotPasswordSubmitHandler } from "./helpers";
import Container from "./Container";
import { message } from "antd";
import styled, { css } from "styled-components";
import { getAppsmithConfigs } from "ee/configs";
import { LoginForm, StyledEmailIcon } from "./Login";
import UserApi from "ee/api/UserApi";
import { isEmpty } from "lodash";

export const FormBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  a > span {
    font-size: 16px;
  }
`;

const { mailEnabled } = getAppsmithConfigs();

const validate = (values: ForgotPasswordFormValues) => {
  const errors: ForgotPasswordFormValues = {};
  if (!values.email || isEmptyString(values.email)) {
    errors.email = createMessage(FORM_VALIDATION_EMPTY_EMAIL);
  } else if (!isEmail(values.email)) {
    errors.email = createMessage(FORM_VALIDATION_INVALID_EMAIL);
  }
  return errors;
};

type ForgotPasswordProps = InjectedFormProps<
  ForgotPasswordFormValues,
  { emailValue: string; verifyCode: string }
> &
  RouteComponentProps<{ email: string }> & {
    emailValue: string;
    verifyCode: string;
  };

export const ForgotPassword = (props: ForgotPasswordProps) => {
  const { error, handleSubmit, submitFailed, submitSucceeded, submitting } =
    props;
  const dispatch = useDispatch();
  const mailEnabled = window.APPSMITH_FEATURE_CONFIGS.mailEnabled;

  const [captchaBlobUrl, setCaptchaBlob] = useState<any>();

  const fetchCaptcha = async () => {
    const res: any = await UserApi.fetchCaptcha();
    const codeImg = window.URL.createObjectURL(res);
    setCaptchaBlob(codeImg);
  };
  useEffect(() => {
    fetchCaptcha();
  }, []);

  useEffect(() => {
    if (submitSucceeded) {
      props.reset();
      dispatch(change(FORGOT_PASSWORD_FORM_NAME, "email", ""));
      dispatch(change(FORGOT_PASSWORD_FORM_NAME, "verifyCode", ""));
    }
  }, [props.emailValue, props.verifyCode]);

  useEffect(() => {
    if (!mailEnabled) {
      message.open({
        type: "error",
        duration: 30,
        content: `系统未开通邮件服务，不能正常发送重置邮件`,
        className: "my-msg",
      });
    } else {
      message.open({
        type: "success",
        duration: 10,
        content: `您的邮箱服务已配置，可正常发送邮件`,
        className: "my-msg",
      });
    }
    return () => {
      message.destroy();
    };
  }, []);

  return (
    <Container
      subtitle={
        <Link
          className="text-sm justify-center"
          startIcon="arrow-left-line"
          target="_self"
          to={AUTH_LOGIN_URL}
        >
          {createMessage(FORGOT_PASSWORD_PAGE_LOGIN_LINK)}
        </Link>
      }
      title={createMessage(FORGOT_PASSWORD_PAGE_TITLE)}
    >
      {/* <FormMessagesContainer>
        {submitFailed && error && <Callout kind="warning">{error}</Callout>}
      </FormMessagesContainer> */}
      <StyledForm onSubmit={handleSubmit(forgotPasswordSubmitHandler)}>
        <FormGroup
          intent={error ? "danger" : "none"}
          style={{ position: "relative" }}
        >
          <LoginForm>
            <FormTextField
              disabled={submitting}
              name="email"
              placeholder={createMessage(
                FORGOT_PASSWORD_PAGE_EMAIL_INPUT_PLACEHOLDER,
              )}
              className="pp-height"
            />
          </LoginForm>
          <StyledEmailIcon height={15} width={15} />
        </FormGroup>
        <FormGroup intent={"none"}>
          <div className="flex justify-between">
            <div className="w-2/3">
              <FormTextField
                className="pp-height"
                name={"verifyCode"}
                placeholder={createMessage(
                  LOGIN_PAGE_CAPTCHA_INPUT_PLACEHOLDER,
                )}
                type="text"
              />
            </div>
            <div className="w-1/3 flex justify-end">
              <a
                onClick={async () => fetchCaptcha()}
                className="flex items-center"
              >
                <img
                  alt={"captcha"}
                  src={captchaBlobUrl}
                  style={{
                    width: "100px",
                    height: "45px",
                    cursor: "pointer",
                  }}
                  title={createMessage(LOGIN_PAGE_REFRESH_CAPTCHA_TEXT)}
                />
              </a>
            </div>
          </div>
        </FormGroup>
        <FormActions className="pp-height pp-font">
          <Button
            isDisabled={
              !(mailEnabled && isEmail(props.emailValue)) &&
              isEmpty(props.verifyCode)
            }
            isLoading={submitting}
            size="md"
            type="submit"
          >
            {createMessage(FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON_TEXT)}
          </Button>
        </FormActions>
      </StyledForm>
      {/* 底部提示 */}
      <FormBottom>
        {createMessage(ALREADY_HAVE_AN_ACCOUNT)}
        <Link
          className="t--sign-up t--signup-link"
          kind="primary"
          target="_self"
          to={AUTH_LOGIN_URL}
        >
          {createMessage(SIGNUP_PAGE_LOGIN_LINK_TEXT)}
        </Link>
      </FormBottom>
    </Container>
  );
};

const selector = formValueSelector(FORGOT_PASSWORD_FORM_NAME);

export default connect((state, props: ForgotPasswordProps) => {
  const queryParams = new URLSearchParams(props.location.search);
  return {
    initialValues: {
      email: queryParams.get("email") || "",
      verifyCode: queryParams.get("verifyCode") || "",
    },
    emailValue: selector(state, "email"),
    verifyCode: selector(state, "verifyCode"),
  };
})(
  reduxForm<
    ForgotPasswordFormValues,
    { emailValue: string; verifyCode: string }
  >({
    validate,
    form: FORGOT_PASSWORD_FORM_NAME,
    touchOnBlur: true,
  })(withRouter(ForgotPassword)),
);
