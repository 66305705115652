import styled from "styled-components";
import {
  APPLICATION_TITLE_MAX_WIDTH,
  APPLICATION_TITLE_MAX_WIDTH_MOBILE,
  NAVIGATION_SETTINGS,
} from "constants/AppConstants";
import type { NavigationSetting } from "constants/AppConstants";
import { THEMEING_TEXT_SIZES } from "constants/ThemeConstants";
import { getApplicationNameTextColor } from "pages/AppViewer/utils";

export const StyledApplicationName = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  navStyle: NavigationSetting["navStyle"];
  navOrientation: NavigationSetting["orientation"];
  forSidebar?: boolean;
  isMobile: boolean;
}>`
  color: ${({ navColorStyle, primaryColor }) =>
    getApplicationNameTextColor(primaryColor, navColorStyle)};
  font-size: ${THEMEING_TEXT_SIZES.base};

  margin: ${(props) =>
    props.navStyle !== "stacked" ? "8px 0" : "8px 0 8px 8px"};
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  transition: width 0.1s;
  ${({ forSidebar, isMobile, navStyle }) => {
    if (isMobile) {
      return `max-width: ${APPLICATION_TITLE_MAX_WIDTH_MOBILE}px;`;
    } else if (
      navStyle === NAVIGATION_SETTINGS.NAV_STYLE.STACKED &&
      !isMobile
    ) {
      return `max-width: 500px;`;
    } else if (forSidebar) {
      return `max-width: ${APPLICATION_TITLE_MAX_WIDTH - 40}px;`;
    } else {
      return `width: 100%`;
    }
  }}
`;

export const StyledApplicationLogo = styled.div<{
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  navStyle: NavigationSetting["navStyle"];
  navOrientation: NavigationSetting["orientation"];
  forSidebar?: boolean;
  isMobile: boolean;
}>`
  font-size: ${THEMEING_TEXT_SIZES.base};
  display: flex;
  ${({ navOrientation, isMobile }) =>
    navOrientation === NAVIGATION_SETTINGS.ORIENTATION.TOP && !isMobile
      ? ""
      : "justify-content: center;"}
  align-items: center;
  margin: ${(props) =>
    props.navOrientation === "side" ? "20px 0px 8px" : "8px 0"};
  ${({ forSidebar, isMobile, navOrientation, navStyle }) => {
    if (isMobile) {
      return `max-width: ${APPLICATION_TITLE_MAX_WIDTH_MOBILE}px;`;
    } else if (
      navStyle === NAVIGATION_SETTINGS.NAV_STYLE.STACKED &&
      !isMobile
    ) {
      return `max-width: 500px;`;
    } else if (forSidebar) {
      return `max-width: ${APPLICATION_TITLE_MAX_WIDTH - 40}px;`;
    } else if (navOrientation === NAVIGATION_SETTINGS.ORIENTATION.SIDE) {
      return `width: 100%`;
    } else if (navStyle === NAVIGATION_SETTINGS.NAV_STYLE.INLINE) {
      return `width: ${APPLICATION_TITLE_MAX_WIDTH}px;`;
    } else {
      return `width: ${APPLICATION_TITLE_MAX_WIDTH}px;`;
    }
  }}
`;
