export interface OptionType {
  label?: string;
  value?: string;
}

type OptionTypeWithSubtext = OptionType & {
  subtext?: string;
};

export const proficiencyOptions: OptionTypeWithSubtext[] = [
  {
    label: "初学者",
    subtext: "我从未写过代码。",
    value: "Brand New",
  },
  {
    label: "新手",
    subtext: "在学习入门，基本了解编码概念。",
    value: "Novice",
  },
  {
    label: "中级",
    subtext: "能够处理中等复杂的项目。",
    value: "Intermediate",
  },
  {
    label: "高级",
    subtext: "开发能力精通，擅长处理复杂的编码任务。",
    value: "Advanced",
  },
];

export const useCaseOptions: OptionTypeWithSubtext[] = [
  {
    label: "工作项目",
    value: "work project",
  },
  {
    label: "个人项目",
    value: "personal project",
  },
];
