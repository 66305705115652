import { useLocation } from "react-router-dom";
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import type {
  ApplicationPayload,
  Page,
} from "ee/constants/ReduxActionConstants";
import { View } from "@tarojs/components";
import history from "utils/history";
// import { NAVIGATION_SETTINGS } from "constants/AppConstants";
import { useWindowSizeHooks } from "utils/hooks/dragResizeHooks";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getAppMode } from "ee/selectors/applicationSelectors";
import { APP_MODE } from "entities/App";
import { builderURL, viewerURL } from "ee/RouteBuilder";
import MenuItem from "./components/MenuItem";
import { Container } from "./TopInline.styled";
import MenuItemContainer from "./components/MenuItemContainer";
import { NAVIGATION_SETTINGS, SIDEBAR_WIDTH } from "constants/AppConstants";
import { getCurrentPage } from "selectors/editorSelectors";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import { useSelector } from "react-redux";
import { getIsAppSettingsPaneWithNavigationTabOpen } from "selectors/appSettingsPaneSelectors";
import { throttle, get as _get, size as _size, head as _head } from "lodash";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { Menu, ConfigProvider } from "antd";
import {
  makeRouteNode,
  getMenuItemTextColor,
  getSecondaryMenuContainerBackgroundColor,
  getMenuContainerBackgroundColor,
  findPathNodes,
  getPath,
} from "../utils";
import { Icon } from "design-system";
import { IconSize } from "@design-system/widgets-old";

import { filterHiddenTreeData, mapClearTree } from "utils/treeUtils";
import { renderIcon } from "components/common/AntdIcon";
// TODO - @Dhruvik - ImprovedAppNav
// Replace with NavigationProps if nothing changes
// appsmith/app/client/src/pages/AppViewer/Navigation/constants.ts
interface TopInlineProps {
  currentApplicationDetails?: ApplicationPayload;
  pages: Page[];
}

const MyMenu = styled(Menu)<{
  theme: string;
  primaryColor: string;
  navColorStyle: any;
}>`
  color: "rgba(0,0,0,0.65)";
  max-width: 90%;
  .ant-menu-submenu {
    font-weight: 500;
  }
  .ant-menu-item {
    transition:
      border-color 0.1s,
      background 0.1s,
      color 0s !important;
  }
  .ant-menu-title-content {
    transition:
      opacity 0.1s,
      background 0.1s,
      color 0s !important;
  }
  .ant-menu-submenu-popup > .ant-menu .ant-menu-submenu-title {
    transition:
      opacity 0.1s,
      background 0.1s,
      color 0s !important;
  }
  .anticon {
    transition:
      opacity 0.1s,
      background 0.1s,
      color 0s !important;
  }
  // .ant-menu-light .ant-menu-item,
  // .ant-menu-light > .ant-menu .ant-menu-item,
  // .ant-menu-light .ant-menu-submenu-title,
  // .ant-menu-light > .ant-menu .ant-menu-submenu-title {
  //   padding-inline: 16px !important;
  // }
`;

const MenuContainer = styled.div<{
  isCenter?: boolean;
}>`
  max-width: 90%;
  margin-left: -16px;
  .ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu::after {
    inset-inline: 0 !important;
  }
  .ant-menu-submenu-popup .ant-menu-vertical .ant-menu-submenu-title {
    padding-inline: 16px !important;
  }
  // .ant-menu-light .ant-menu-item,
  // .ant-menu-light > .ant-menu .ant-menu-item,
  // .ant-menu-light .ant-menu-submenu-title,
  // .ant-menu-light > .ant-menu .ant-menu-submenu-title {
  //   padding-inline: 16px !important;
  // }
`;

export function TopInline(props: TopInlineProps) {
  const { currentApplicationDetails, pages } = props;
  const selectedTheme = useSelector(getSelectedAppTheme);
  const navColorStyle =
    _get(
      currentApplicationDetails,
      "applicationDetail.navigationSetting.colorStyle",
      "",
    ) || NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT;
  const primaryColor = _get(
    selectedTheme,
    "properties.colors.primaryColor",
    "inherit",
  );
  const location = useLocation();
  const navRef = useRef<HTMLDivElement>(null);
  const appMode = useSelector(getAppMode);
  const currentPage = useSelector(getCurrentPage);
  const currentApp = useSelector(getCurrentApplication);

  const gotToPath = (pId: string, path: string) => {
    history.push(path);
  };
  const viewerLayout = currentApp?.viewerLayout;

  const initState = useMemo(() => {
    let menudata: any = [];
    const pagesMap = pages.reduce((a: any, c: any) => {
      a[c.pageId] = { ...c };
      return a;
    }, {});
    if (viewerLayout && pages.length) {
      try {
        const current = JSON.parse(viewerLayout);

        const _outsiderTree = current.outsiderTree || [];
        menudata = current?.treeData
          .map((itdata: any) => {
            return mapClearTree(itdata, (item: any) => {
              const path = getPath(item, pagesMap, item.pageId, appMode);
              const title = _get(
                pagesMap,
                [item.pageId, "pageName"],
                item.title,
              ); //用最新的 pageName
              if (_outsiderTree.find((n: any) => n.pageId === item.pageId)) {
                return false;
              }
              if (title && item.isPage && !pagesMap[item.pageId]) {
                // 不存在的 pageId, 删除掉
                return false;
              }
              return {
                ...item,
                key: item.pageId || item.key,
                children: _size(item.children) ? item.children : null,
                label: item.pageId ? (
                  <a
                    key={item.pageId}
                    onClick={() => gotToPath(item.pageId, path)}
                  >
                    {title}
                  </a>
                ) : (
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5px",
                    }}
                  >
                    {title}
                    <span className="custom-expand-icon">
                      {_size(item.children) ? (
                        <Icon name="arrow-down-s-line" size={"md"} />
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                ),
                // icon: renderIcon(item.icon),
                icon: (() => {
                  if (!item.icon) {
                    return null;
                  }
                  return item.icon.iconName !== "(none)" &&
                    item.icon.iconName !== "NONE" ? (
                    <div style={{ verticalAlign: "-0.3em" }}>
                      {renderIcon(item.icon)}
                    </div>
                  ) : null;
                })(),
              };
            });
          })
          .filter((i: any) => i);
      } catch (e) {
        console.log(e);
      }
    } else {
      menudata = pages.map((p) => {
        const path = getPath(p, pagesMap, p.pageId, appMode);
        return {
          title: p.pageName,
          pageId: p.pageId,
          isPage: true,
          key: p.pageId,
          label: p.pageId ? (
            <a key={p.pageId} onClick={() => gotToPath(p.pageId, path)}>
              {p.pageName}
            </a>
          ) : (
            p.pageName
          ),
          children: null,
        };
      });
    }
    return {
      menudata,
    };
  }, [viewerLayout, pages, currentApplicationDetails]);

  const activeMenuKeys = useMemo(() => {
    const currentPageId: any = currentPage?.pageId;
    const parentPaths = findPathNodes(initState.menudata, currentPageId);
    return { parentPaths };
  }, [currentPage?.pageId, initState.menudata]);

  useEffect(() => {
    // setQuery(window.location.search);
  }, [location]);
  // Mark default page as first page
  const appPages = pages;
  if (appPages.length > 1) {
    appPages.forEach((item, i) => {
      if (item.isDefault) {
        appPages.splice(i, 1);
        appPages.unshift(item);
      }
    });
  }

  if (
    !_size(initState.menudata) ||
    (_size(initState.menudata) === 1 &&
      !_get(initState.menudata, ["0", "children"], ""))
  )
    return null;
  const current_theme =
    _get(
      currentApplicationDetails,
      ["applicationDetail", "navigationSetting", "colorStyle"],
      "theme",
    ) === "theme"
      ? "dark"
      : "light";
  const isMenuPositionCenter =
    _get(
      currentApplicationDetails,
      ["applicationDetail", "navigationSetting", "position"],
      "",
    ) === "center";

  return (
    <Container
      className={`gap-x-2 flex items-center ${
        isMenuPositionCenter ? "justify-center" : ""
      } t--app-viewer-navigation-top-inline`}
      navColorStyle={navColorStyle}
      primaryColor={primaryColor}
      ref={navRef}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: primaryColor,
          },
          components: {
            Menu: {
              // activeBarWidth: 0,
              itemPaddingInline: 16,
              darkItemSelectedColor: primaryColor,
              popupBg: "white",
              darkItemColor: "rgba(255, 255, 255, 0.8)",
              darkSubMenuItemBg: "white",
              darkItemHoverColor: getMenuItemTextColor(
                primaryColor,
                navColorStyle,
              ),
            },
          },
        }}
      >
        <MenuContainer className="menu-container">
          <MyMenu
            className="rootSideMenu pp-menu"
            defaultSelectedKeys={activeMenuKeys.parentPaths}
            items={filterHiddenTreeData(initState.menudata)}
            mode="horizontal"
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
            theme={current_theme}
          />
        </MenuContainer>
      </ConfigProvider>
    </Container>
  );
}

export default TopInline;
