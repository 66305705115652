import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { UploadFile } from "antd";

export interface ImportModuleRequest {
  folderId?: string;
  moduleFile: UploadFile;
  onSuccessCallback?: () => void;
}

// 初始化文件夹
export const getAllFiders = (name: string) => {
  return {
    type: ReduxActionTypes.INIT_MODULES_FOLDERS,
    payload: {
      name,
    },
  };
};

// 创建文件夹
export const addFolder = (name: string) => {
  return {
    type: ReduxActionTypes.ADD_FOLDER,
    payload: {
      name,
    },
  };
};

// 更新文件夹命名
export const updateFolderName = (name: string, id: string) => {
  return {
    type: ReduxActionTypes.UPDATE_FOLDER_NAME,
    payload: {
      name,
      id,
    },
  };
};

// 删除文件夹
export const deleteFolder = (id: string) => {
  return {
    type: ReduxActionTypes.DELETE_FOLDER,
    payload: {
      id,
    },
  };
};

// 初始化模块
export const getAllModules = (name: string) => {
  return {
    type: ReduxActionTypes.INIT_MODULES,
    payload: {
      name,
    },
  };
};

// 创建模块
export const addModule = (name: string, folderId?: string) => {
  return {
    type: ReduxActionTypes.ADD_MODULE,
    payload: {
      name,
      folderId,
    },
  };
};

// 删除模块
export const deleteModule = (id: string, folderId?: string) => {
  return {
    type: ReduxActionTypes.DELETE_MODULE,
    payload: {
      id,
      folderId,
    },
  };
};

// 更新文件夹命名
export const updateModuleName = (
  name: string,
  id: string,
  folderId?: string,
) => {
  return {
    type: ReduxActionTypes.MODULE_RENAME,
    payload: {
      name,
      id,
      folderId,
    },
  };
};

// 复制模块
export const copyModule = (name: string, id: string, folderId?: string) => {
  return {
    type: ReduxActionTypes.COPY_MODULE,
    payload: {
      name,
      id,
      folderId,
    },
  };
};

// 移动模块到文件夹
export const moveModule = (
  obj: Record<string, string | boolean>,
  currentFolderId: string,
) => {
  return {
    type: ReduxActionTypes.MOVE_MODULE_TO_FOLDER,
    payload: {
      id: obj.id,
      name: obj.name,
      folderId: obj.folderId,
      folderName: obj.folderName,
      currentFolderId,
    },
  };
};

export const importApplication = (moduleDetails: ImportModuleRequest) => {
  return {
    type: ReduxActionTypes.IMPORT_MODULE,
    payload: moduleDetails,
  };
};

// 文件夹内
// 初始化模块
export const getFolderModules = (name: string, folderId: string) => {
  return {
    type: ReduxActionTypes.INIT_FOLDER_MODULES,
    payload: {
      name,
      folderId,
    },
  };
};

// // 创建模块
// export const addFolderModule = (name: string) => {
//   return {
//     type: ReduxActionTypes.ADD_FOLDER_MODULE,
//     payload: {
//       name,
//     },
//   };
// };

// // 删除模块
// export const deleteFolderModule = (id: string) => {
//   return {
//     type: ReduxActionTypes.DELETE_FOLDER_MODULE,
//     payload: {
//       id,
//     },
//   };
// };

// // 更新文件夹命名
// export const updateFolderModuleName = (name: string, id: string) => {
//   return {
//     type: ReduxActionTypes.FOLDER_MODULE_RENAME,
//     payload: {
//       name,
//       id,
//     },
//   };
// };

// // 复制模块
// export const copyFolderModule = (name: string, id: string) => {
//   return {
//     type: ReduxActionTypes.COPY_FOLDER_MODULE,
//     payload: {
//       name,
//       id,
//     },
//   };
// };

// // 移动模块到文件夹
// export const moveFolderModule = (obj: Record<string, string | boolean>) => {
//   return {
//     type: ReduxActionTypes.FOLDER_MOVE_MODULE_TO_FOLDER,
//     payload: {
//       id: obj.id,
//       name: obj.name,
//       folderId: obj.folderId,
//       folderName: obj.folderName,
//     },
//   };
// };
