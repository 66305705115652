import React, { useEffect, useRef, useState } from "react";
import type { NavigationSetting } from "constants/AppConstants";
import { StyledApplicationName } from "./ApplicationName.styled";
import { isEllipsisActive } from "utils/helpers";
import { Tooltip } from "antd";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";

interface ApplicationNameProps {
  appName?: string;
  navColorStyle: NavigationSetting["colorStyle"];
  navStyle: NavigationSetting["navStyle"];
  primaryColor: string;
  forSidebar?: boolean;
  fontWeight?: "regular" | "bold";
  showAbbr?: boolean;
  navOrientation: NavigationSetting["orientation"];
}

const ApplicationName = (props: ApplicationNameProps) => {
  const {
    appName,
    forSidebar,
    navColorStyle,
    navStyle,
    primaryColor,
    showAbbr,
    navOrientation,
  } = props;
  const applicationNameRef = useRef<HTMLDivElement>(null);
  const [ellipsisActive, setEllipsisActive] = useState(false);
  const isMobile = useIsMobileDevice();

  useEffect(() => {
    if (isEllipsisActive(applicationNameRef?.current)) {
      setEllipsisActive(true);
    }
  }, [applicationNameRef, appName]);

  const viewName = showAbbr ? appName?.slice(0, 1) : appName;
  return (
    <Tooltip showArrow={false} title={ellipsisActive ? appName || "" : ""}>
      <StyledApplicationName
        className="t--app-viewer-application-name"
        forSidebar={forSidebar}
        isMobile={isMobile}
        navColorStyle={navColorStyle}
        navStyle={navStyle}
        navOrientation={navOrientation}
        primaryColor={primaryColor}
        ref={applicationNameRef}
      >
        {viewName}
      </StyledApplicationName>
    </Tooltip>
  );
};

export default ApplicationName;
