import { createReducer } from "utils/ReducerUtils";
import type {
  ReduxAction,
  UpdateCanvasPayload,
} from "ee/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import moment from "moment";
import type {
  LayoutOnLoadActionErrors,
  PageAction,
} from "constants/AppsmithActionConstants/ActionConstants";
import type { UpdatePageResponse } from "api/PageApi";

export const initialState: EditorReduxState = {
  widgetConfigBuilt: false,
  initialized: false,
  loadingStates: {
    publishing: false,
    publishingError: false,
    saving: false,
    savingError: false,
    savingEntity: false,
    loading: false,
    loadingError: false,
    pageSwitchingError: false,
    isPageSwitching: false,
    creatingPage: false,
    creatingPageError: false,
    cloningPage: false,
    cloningPageError: false,
    updatingWidgetName: false,
    updateWidgetNameError: false,
  },
  isSnipingMode: false,
  snipModeBindTo: undefined,
  isPreviewMode: false,
  isProtectedMode: true,
  zoomLevel: 1,
};

export const handlers = {
  [ReduxActionTypes.RESET_MODULE_EDITOR_REQUEST]: (state: EditorReduxState) => {
    return {
      ...state,
      currentPageId: undefined,
      currentPageName: undefined,
      currentLayoutId: undefined,
      currentApplicationId: undefined,
      pageWidgetId: undefined,
      pageActions: undefined,
      layoutOnLoadActionErrors: undefined,
    };
  },

  [ReduxActionTypes.RESET_MODULE_EDITOR_SUCCESS]: (state: EditorReduxState) => {
    return { ...state, initialized: false };
  },
  [ReduxActionTypes.INITIALIZE_MODULE_EDITOR_SUCCESS]: (
    state: EditorReduxState,
  ) => {
    return { ...state, initialized: true };
  },
  [ReduxActionTypes.INIT_MODULE_CANVAS_LAYOUT]: (
    state: EditorReduxState,
    action: ReduxAction<UpdateCanvasPayload>,
  ) => {
    const {
      currentApplicationId,
      currentLayoutId,
      currentPageId,
      currentPageName,
      layoutOnLoadActionErrors,
      pageActions,
      pageWidgetId,
    } = action.payload;
    state.loadingStates.publishing = false;
    state.loadingStates.publishingError = false;
    return {
      ...state,
      currentPageName,
      currentLayoutId,
      pageWidgetId,
      currentApplicationId,
      currentPageId,
      pageActions,
      layoutOnLoadActionErrors,
    };
  },
  //   [ReduxActionTypes.UPDATE_PAGE_SUCCESS]: (
  //     state: EditorReduxState,
  //     action: ReduxAction<UpdatePageResponse>,
  //   ) => {
  //     if (action.payload.id === state.currentPageId) {
  //       return { ...state, currentPageName: action.payload.name };
  //     }
  //     return state;
  //   },
};

const moduleEditorReducer = createReducer(initialState, handlers);

export interface EditorReduxState {
  widgetConfigBuilt: boolean;
  initialized: boolean;
  pageWidgetId?: string;
  currentLayoutId?: string;
  currentPageName?: string;
  currentPageId?: string;
  lastUpdatedTime?: number;
  pageActions?: PageAction[][];
  isSnipingMode: boolean;
  snipModeBindTo?: string;
  isPreviewMode: boolean;
  isProtectedMode: boolean;
  zoomLevel: number;
  layoutOnLoadActionErrors?: LayoutOnLoadActionErrors[];
  loadingStates: {
    saving: boolean;
    savingError: boolean;
    savingEntity: boolean;
    publishing: boolean;
    published?: string;
    publishingError: boolean;
    loading: boolean;
    loadingError: boolean;
    isPageSwitching: boolean;
    pageSwitchingError: boolean;
    creatingPage: boolean;
    creatingPageError: boolean;
    cloningPage: boolean;
    cloningPageError: boolean;
    updatingWidgetName: boolean;
    updateWidgetNameError: boolean;
  };
}

export default moduleEditorReducer;
