import React, { useEffect, useState, useMemo } from "react";
import type {
  ApplicationPayload,
  Page,
} from "ee/constants/ReduxActionConstants";
import { NAVIGATION_SETTINGS, SIDEBAR_WIDTH } from "constants/AppConstants";
import { get, get as _get, head as _head, size as _size } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";
import ApplicationName from "./components/ApplicationName";
import { useHref } from "pages/Editor/utils";
import { builderURL, viewerURL } from "ee/RouteBuilder";
import {
  combinedPreviewModeSelector,
  getCurrentPageId,
} from "selectors/editorSelectors";
import type { User } from "constants/userConstants";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import SidebarProfileComponent from "./components/SidebarProfileComponent";
import CollapseButton from "./components/CollapseButton";
import classNames from "classnames";
import { useMouse } from "@mantine/hooks";
import {
  getAppSidebarPinned,
  getCurrentApplication,
  getAppMode,
} from "ee/selectors/applicationSelectors";
import { setIsAppSidebarPinned } from "ee/actions/applicationActions";
import {
  StyledCtaContainer,
  StyledFooter,
  StyledHeader,
  StyledImage,
  StyledMenuContainer,
  StyledSidebar,
} from "./Sidebar.styled";
import { View } from "@tarojs/components";
import { getCurrentThemeDetails } from "selectors/themeSelectors";
import { getCurrentPage } from "selectors/editorSelectors";
import { getIsAppSettingsPaneWithNavigationTabOpen } from "selectors/appSettingsPaneSelectors";
import ApplicationLogo from "./components/ApplicationLogo";
import history from "utils/history";
import { APP_MODE } from "entities/App";
import { ConfigProvider, Menu } from "antd";
import { filterHiddenTreeData, mapClearTree } from "utils/treeUtils";
import { Icon } from "design-system";
import { makeRouteNode } from "../utils";
import styled from "styled-components";
import {
  getMenuContainerBackgroundColor,
  findPathNodes,
  getPath,
} from "../utils";
import { renderIcon } from "components/common/AntdIcon";
// import NavigationLogo from "ee/pages/AppViewer/NavigationLogo";

interface SidebarProps {
  currentApplicationDetails?: ApplicationPayload;
  pages: Page[];
  currentWorkspaceId: string;
  currentUser: User | undefined;
  showUserSettings: boolean;
}

const MyMenu = styled(Menu)<{
  theme: string;
  primaryColor: string;
  navColorStyle: any;
}>`
  color: "rgba(0,0,0,0.65)";
  .ant-menu-submenu {
    font-weight: 500;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    border-radius: 6px !important;
  }
`;
const TheCollapsedIcon = styled.div<{
  isOpen: boolean;
  theme: string;
}>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 22px 8px;
  > span {
    width: 20px;
  }
`;

export function Sidebar(props: SidebarProps) {
  const selectedTheme = useSelector(getSelectedAppTheme);
  const appMode = useSelector(getAppMode);
  const { currentApplicationDetails, currentUser, currentWorkspaceId, pages } =
    props;
  const navColorStyle =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.colorStyle || NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT;
  const navStyle =
    currentApplicationDetails?.applicationDetail?.navigationSetting?.navStyle ||
    NAVIGATION_SETTINGS.NAV_STYLE.STACKED;
  const isMinimal =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.navStyle === NAVIGATION_SETTINGS.NAV_STYLE.MINIMAL;
  const logoConfiguration =
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.logoConfiguration ||
    NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE;
  const primaryColor = get(
    selectedTheme,
    "properties.colors.primaryColor",
    "inherit",
  );
  const borderRadius = get(
    selectedTheme,
    "properties.borderRadius.appBorderRadius",
    "inherit",
  );
  const location = useLocation();
  const { pathname } = location;
  const [query, setQuery] = useState("");
  const pageId = useSelector(getCurrentPageId);
  const editorURL = useHref(builderURL, { pageId });
  const dispatch = useDispatch();
  const isPinned = useSelector(getAppSidebarPinned);
  const [isOpen, setIsOpen] = useState(true);
  const theme = useSelector(getCurrentThemeDetails);
  const isPreviewMode = useSelector(combinedPreviewModeSelector);
  const isAppSettingsPaneWithNavigationTabOpen = useSelector(
    getIsAppSettingsPaneWithNavigationTabOpen,
  );
  // const { x } = useMouse();
  // const [isLogoVisible, setIsLogoVisible] = useState(false);

  const currentApp = useSelector(getCurrentApplication);
  const currentPage = useSelector(getCurrentPage);
  const viewerLayout = currentApp?.viewerLayout;
  const pagesMap = pages?.reduce((a: any, c: any) => {
    a[c.pageId] = { ...c };
    return a;
  }, {});

  const gotToPath = (pId: string, path: string) => {
    history.push(path);
  };

  const clickMenu = (item: any) => {
    const target_path = getPath(
      { pageId: item.pageId },
      pagesMap,
      item.pageId,
      appMode,
    );
    if (target_path) {
      history.push(target_path);
    }
  };

  const initState = useMemo(() => {
    let menudata: any = [];
    if (viewerLayout && pages.length) {
      try {
        const current = JSON.parse(viewerLayout);
        const _outsiderTree = current.outsiderTree || [];
        menudata = current?.treeData.map((itdata: any) => {
          return mapClearTree(itdata, (item: any) => {
            // const path = getPath(item, pagesMap, item.title,);
            const title = _get(pagesMap, [item.pageId, "pageName"], item.title); //用最新的 pageName
            if (_outsiderTree.find((n: any) => n.pageId === item.pageId)) {
              return false;
            }
            if (item.title && item.isPage && !pagesMap[item.pageId]) {
              return false;
            }
            return {
              ...item,
              key: item.pageId || item.key,
              children: _size(item.children) ? item.children : null,
              label: title,
              // icon: renderIcon(item.icon),
              icon: (() => {
                if (!item.icon) {
                  return null;
                }
                return item.icon.iconName !== "(none)" &&
                  item.icon.iconName !== "NONE" ? (
                  <div style={{ verticalAlign: "-0.3em" }}>
                    {renderIcon(item.icon)}
                  </div>
                ) : null;
              })(),
            };
          });
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      menudata = pages.map((p) => {
        // const path = getPath(p, pagesMap, p.pageName);
        return {
          title: p.pageName,
          pageId: p.pageId,
          isPage: true,
          key: p.pageId,
          label: p.pageName,
          children: null,
        };
      });
    }
    return {
      menudata,
    };
  }, [viewerLayout, pages, currentApplicationDetails]);

  const activeMenuKeys = useMemo(() => {
    const currentPageId: any = currentPage?.pageId;
    const parentPaths = findPathNodes(initState.menudata, currentPageId);
    return { parentPaths };
  }, [currentPage?.pageId, initState.menudata]);

  const [openkeys, setOpenKeys] = useState(activeMenuKeys.parentPaths);

  useEffect(() => {
    setOpenKeys(activeMenuKeys.parentPaths);
  }, [activeMenuKeys.parentPaths]);

  useEffect(() => {
    setQuery(window.location.search);
  }, [location]);
  // Mark default page as first page
  const appPages = pages;
  if (appPages.length > 1) {
    appPages.forEach((item, i) => {
      if (item.isDefault) {
        appPages.splice(i, 1);
        appPages.unshift(item);
      }
    });
  }

  useEffect(() => {
    setIsOpen(isPinned);
  }, [isPinned]);

  const setIsPinned = (isPinned: boolean) => {
    dispatch(setIsAppSidebarPinned(isPinned));
  };

  const calculateSidebarHeight = () => {
    let prefix = `calc( 100vh - `;
    const suffix = ")";

    if (isPreviewMode) {
      prefix += `${theme.smallHeaderHeight} - ${theme.bottomBarHeight}`;
    } else if (isAppSettingsPaneWithNavigationTabOpen) {
      prefix += `${theme.smallHeaderHeight} - ${theme.bottomBarHeight} - 66px`;
    } else {
      prefix += "0px";
    }

    return prefix + suffix;
  };
  const current_theme =
    get(
      currentApplicationDetails,
      ["applicationDetail", "navigationSetting", "colorStyle"],
      "theme",
    ) === "theme"
      ? "dark"
      : "light";
  return (
    <StyledSidebar
      className={classNames({
        "t--app-viewer-navigation-sidebar": true,
        "is-open": isOpen,
        "shadow-xl": !isPinned,
      })}
      isMinimal={isMinimal}
      navColorStyle={navColorStyle}
      primaryColor={primaryColor}
      sidebarHeight={calculateSidebarHeight()}
    >
      <StyledMenuContainer
        navColorStyle={navColorStyle}
        primaryColor={primaryColor}
      >
        {!isMinimal &&
          (logoConfiguration ===
            NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE ||
            logoConfiguration ===
              NAVIGATION_SETTINGS.LOGO_CONFIGURATION
                .APPLICATION_TITLE_ONLY) && (
            <ApplicationName
              appName={currentApplicationDetails?.name}
              navColorStyle={navColorStyle}
              navOrientation={NAVIGATION_SETTINGS.ORIENTATION.SIDE}
              navStyle={navStyle}
              primaryColor={primaryColor}
              showAbbr={!isOpen}
            />
          )}
        <ApplicationLogo
          appName={currentApplicationDetails?.name}
          navColorStyle={navColorStyle}
          navOrientation={NAVIGATION_SETTINGS.ORIENTATION.SIDE}
          navStyle={navStyle}
          primaryColor={primaryColor}
          showAbbr={!isOpen}
        />
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: primaryColor,
            },
            components: {
              Menu: {
                itemPaddingInline: 16,
                darkItemSelectedColor: primaryColor,
                darkSubMenuItemBg: getMenuContainerBackgroundColor(
                  primaryColor,
                  navColorStyle,
                ),
                itemBorderRadius: 6,
                subMenuItemBorderRadius: 6,
                horizontalItemBorderRadius: 6,
              },
            },
          }}
        >
          <MyMenu
            className={`rootSideMenu pp-menu`}
            defaultSelectedKeys={activeMenuKeys.parentPaths}
            expandIcon={(props: any) => {
              if (!isOpen) {
                return null;
              }
              return props.isOpen ? (
                <Icon name="arrow-down-s-line" size={"md"} />
              ) : (
                <Icon name="arrow-right-s-line" size={"md"} />
              );
            }}
            inlineCollapsed={!isOpen}
            items={filterHiddenTreeData(initState.menudata)}
            mode="inline"
            navColorStyle={navColorStyle}
            onClick={({ item }) => {
              clickMenu(item.props);
            }}
            onOpenChange={(e: any) => setOpenKeys(e)}
            openKeys={openkeys}
            primaryColor={primaryColor}
            selectedKeys={activeMenuKeys.parentPaths}
            theme={current_theme}
          />
        </ConfigProvider>
      </StyledMenuContainer>
      {props.showUserSettings && (
        <StyledFooter navColorStyle={navColorStyle} primaryColor={primaryColor}>
          <SidebarProfileComponent
            currentUser={currentUser}
            isInline
            isMinimal={isMinimal}
            isOpen={isOpen}
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
          />
        </StyledFooter>
      )}
      {!isMinimal && (
        <TheCollapsedIcon isOpen={isOpen}>
          <CollapseButton
            borderRadius={borderRadius}
            isInline
            isOpen={isOpen}
            isPinned={isPinned}
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
            setIsPinned={setIsPinned}
          />
        </TheCollapsedIcon>
      )}
    </StyledSidebar>
  );
}

export default Sidebar;
