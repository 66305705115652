import { getSearchQuery } from "utils/helpers";
import type { Location } from "history";
import { Module, MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { Plugin } from "api/PluginApi";
import ImageAlt from "assets/images/placeholder-image.svg";
import {
  ENTITY_ICON_SIZE,
  EntityIcon,
  JsFileIconV2,
  dbQueryIcon,
} from "pages/Editor/Explorer/ExplorerIcons";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import tinycolor from "tinycolor2";

export const getIsBranchUpdated = (
  prevLocation: Location<unknown>,
  currentLocation: Location<unknown>,
) => {
  const { search: search1 } = prevLocation;
  const { search: search2 } = currentLocation;

  const branch1 = getSearchQuery(search1, "branch");
  const branch2 = getSearchQuery(search2, "branch");

  return branch1 !== branch2;
};

export const addClassToDocumentRoot = (className: string) => {
  const element: HTMLElement | null = document.querySelector("#root");
  if (!!element) {
    element.classList.add(className);
  }
};

export const removeClassFromDocumentRoot = (className: string) => {
  const element: HTMLElement | null = document.querySelector("#root");
  if (!!element) {
    element.classList.remove(className);
  }
};

function hexToRgb(hexColor: any) {
  // 去除可能包含的 # 号
  hexColor = hexColor.replace("#", "");
  // 拆分十六进制颜色值为 R、G、B 分量
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // 返回 RGB 颜色对象
  return { r, g, b };
}

// 辅助函数：将十六进制颜色转换为RGBA颜色
function hexToRgba(hexColor: any) {
  const rgbColor = hexToRgb(hexColor);

  return {
    r: rgbColor.r,
    g: rgbColor.g,
    b: rgbColor.b,
    a: 1,
  };
}

// 辅助函数：解析RGBA颜色字符串
function parseRgba(rgbaColor: string) {
  const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?)\)$/i;
  const result = rgbaColor.match(regex);

  if (result) {
    return {
      r: parseInt(result[1], 10),
      g: parseInt(result[2], 10),
      b: parseInt(result[3], 10),
      a: parseFloat(result[4]),
    };
  }

  return null;
}

// 辅助函数：将RGBA颜色转换为十六进制颜色
function rgbaToHex(rgbaColor: { r: any; g: any; b: any; a?: number }) {
  const { b, g, r } = rgbaColor;
  return rgbToHex(r, g, b);
}

// 辅助函数：将RGB颜色转换为十六进制颜色
function rgbToHex(r: any, g: any, b: any) {
  const toHex = (c: { toString: (arg0: number) => any }) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// 辅助函数：将两个颜色进行叠加
function blendColors(color1: string, color2: string) {
  const rgbaColor1: any = hexToRgba(color1);
  const rgbaColor2: any = parseRgba(color2);
  const blendedColor = {
    r: Math.round(
      rgbaColor1.r * (1 - rgbaColor2.a) + rgbaColor2.r * rgbaColor2.a,
    ),
    g: Math.round(
      rgbaColor1.g * (1 - rgbaColor2.a) + rgbaColor2.g * rgbaColor2.a,
    ),
    b: Math.round(
      rgbaColor1.b * (1 - rgbaColor2.a) + rgbaColor2.b * rgbaColor2.a,
    ),
    a: 1,
  };

  return rgbaToHex(blendedColor);
}

export function addOverlayEffect(baseColor: string, opacity: number) {
  // 创建一个新的颜色，将其设置为基础颜色和蒙层颜色的叠加效果
  const overlayColor = `rgba(255, 255, 255, ${opacity})`;
  let _baseColor = baseColor;
  if (baseColor.includes("hsl")) {
    _baseColor = tinycolor(baseColor).toHexString();
  }
  if (baseColor.includes("rgb")) {
    _baseColor = tinycolor(baseColor).toHexString();
  }
  // 将基础颜色和蒙层颜色进行叠加
  const combinedColor = blendColors(_baseColor, overlayColor);

  return combinedColor;
}
