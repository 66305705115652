interface Props {
  searchedWorkflows: any;
}

const WorkflowSearchItem = (props: Props) => {
  // eslint-disable-next-line
  const { searchedWorkflows } = props;
  return null;
};

export default WorkflowSearchItem;
