import type { AxiosPromise, AxiosResponse } from "axios";
import Api from "api/Api";
import type { ApiResponse } from "api/ApiResponses";
import { get, omit } from "lodash";

export interface UpdateRoleConfigRequest {
  tabName: string;
  entitiesChanged: {
    id: string;
    permissions: number[];
    type: string;
    name: string;
  }[];
}

interface RoleItem {
  id: string;
  name: string;
}

interface NewRoleItem {
  roleId: string;
  type: string;
}

export interface AssociateRolesRequest {
  users?: {
    id?: string;
    username: string;
  }[];
  groups?: RoleItem[];
  rolesAdded?: RoleItem[];
  rolesRemoved?: RoleItem[];
}

export interface UpdateUserSystemRolesRequest {
  id: string;
  roles?: NewRoleItem[];
}

export interface RoleData {
  name: string;
  id: string;
  description?: string;
  autoCreated: boolean;
}

export interface UpdateRoleRequest {
  name?: string;
  description?: string;
}

class RoleApi extends Api {
  static baseURL = "v1/roles";

  static async fetchRolesForAssign(): Promise<
    AxiosResponse<ApiResponse<unknown>, any>
  > {
    return Api.get(`${RoleApi.baseURL}/assign`);
  }

  static async fetchAllRoles(): Promise<
    AxiosResponse<ApiResponse<unknown>, any>
  > {
    return Api.get(`${RoleApi.baseURL}`);
  }

  static async fetchRole(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.get(`${RoleApi.baseURL}/${id}`);
  }

  static async createRole(
    request: UpdateRoleRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.post(`${RoleApi.baseURL}`, request);
  }

  static async updateRole(
    id: string,
    request: UpdateRoleRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.put(`${RoleApi.baseURL}/${id}`, request);
  }

  static async deleteRole(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.delete(`${RoleApi.baseURL}/${id}`);
  }

  static async fetchRoleConfig(
    id: string,
    type?: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.get(`${RoleApi.baseURL}/configure/${id}?type=${type}`);
  }

  static async updateRoleConfig(
    id: string,
    request: UpdateRoleConfigRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.put(`${RoleApi.baseURL}/configure/${id}`, request);
  }

  static async associateRoles(
    request: AssociateRolesRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.put(`${RoleApi.baseURL}/associate`, request);
  }

  static async updateUserSystemRoles(
    request: UpdateUserSystemRolesRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.put(`v1/user/${request.id}/system/roles`, omit(request, "id"));
  }
}

export default RoleApi;
