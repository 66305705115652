import iconfontUrl from "../../assets/antdfont/iconfont.js?url";
import { createFromIconfontCN } from "@ant-design/icons";
import { Icon, type IconName } from "@blueprintjs/core";
import { View } from "@tarojs/components";

const AntdIcon = createFromIconfontCN({
  scriptUrl: iconfontUrl,
});

export const EMPTY_ICON = {
  iconName: "NONE",
  type: "antd",
  category: "direction",
}

export const exIconInfo = (icon: null | undefined | string | { type:string, iconName: string, category: string }) => {
  if (!icon) return EMPTY_ICON;
  if (typeof icon === "object") {
    return icon;
  } else if (typeof icon === "string") { // taro icon need set null
    return EMPTY_ICON;
  }
  return EMPTY_ICON
}

export const renderIcon = (icon: null | undefined | string | { type:string, iconName: string, category: string }) => {
  if (!icon) return null;
  if (icon === "(none)" || icon === "NONE") return null;
  if (typeof icon === "object") {
    if (icon.type === "appsmith") { // appsmith icon
      if (icon?.iconName === "(none)" || icon?.iconName === "NONE") return null;
      return <Icon icon={icon.iconName as IconName} />
    } else if (icon?.type === "antd") { // antd icon
      if (icon?.iconName === "(none)" || icon?.iconName === "NONE") { // empty icon
        return null;
      }
      return <AntdIcon
        type={icon.iconName ?? ""}
        style={{
          fontSize: "18px",
        }}
      />
    }
    return null;
  } else if (typeof icon === "string") { // taro icon
    return <View
      className={`van-icon van-icon-${icon} taroify-icon taroify-icon--inherit hydrated`}
    />
  }
  return null;
}


export default AntdIcon;
