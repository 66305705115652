interface Props {
  searchedPackages: any;
}

const PackageSearchItem = (props: Props) => {
  // eslint-disable-next-line
  const { searchedPackages } = props;
  return null;
};

export default PackageSearchItem;
