import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import type { noop } from "lodash";

import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { APPLICATIONS_URL } from "constants/routes";

import type { MenuItemData } from "./NavigationMenuItem";
import { MenuTypes } from "./types";
import { getExportAppAPIRoute } from "ee/constants/ApiConstants";

import {
  hasDeleteApplicationPermission,
  isPermitted,
  PERMISSION_TYPE,
} from "ee/utils/permissionHelpers";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { Colors } from "constants/Colors";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import { getIsHelpDocEnabled } from "ee/selectors/tenantSelectors";
import { redoAction, undoAction } from "actions/pageActions";
import { redoShortCut, undoShortCut } from "utils/helpers";
import { toast } from "design-system";
import type { ThemeProp } from "WidgetProvider/constants";
import { DISCORD_URL, DOCS_BASE_URL } from "constants/ThirdPartyConstants";
import { getIsSideBySideEnabled } from "selectors/ideSelectors";

export interface NavigationMenuDataProps extends ThemeProp {
  editMode: typeof noop;
  setForkApplicationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GetNavigationMenuData = ({
  editMode,
  setForkApplicationModalOpen,
}: NavigationMenuDataProps): MenuItemData[] => {
  const dispatch = useDispatch();
  const history = useHistory();

  const applicationId = useSelector(getCurrentApplicationId);
  const isHelpDocEnabled = useSelector(getIsHelpDocEnabled);

  const isApplicationIdPresent = !!(applicationId && applicationId.length > 0);

  const isSideBySideFlagEnabled = useSelector(getIsSideBySideEnabled);

  const currentApplication = useSelector(getCurrentApplication)!;
  const hasExportPermission = isPermitted(
    currentApplication?.userPermissions ?? [],
    PERMISSION_TYPE.EXPORT_APPLICATION,
  );
  const hasEditPermission = isPermitted(
    currentApplication?.userPermissions ?? [],
    PERMISSION_TYPE.MANAGE_APPLICATION,
  );
  const openExternalLink = (link: string) => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  const exportAppAsJSON = () => {
    const id = `t--export-app-link`;
    const existingLink = document.getElementById(id);
    existingLink && existingLink.remove();
    const link = document.createElement("a");

    const branchName = currentApplication?.gitApplicationMetadata?.branchName;
    link.href = getExportAppAPIRoute(applicationId, branchName);
    link.id = id;
    document.body.appendChild(link);
    // @ts-expect-error: Types are not available
    if (!window.Cypress) {
      link.click();
    }
    toast.show(`Successfully exported ${currentApplication?.name}`, {
      kind: "success",
    });
  };

  const deleteApplication = () => {
    if (applicationId && applicationId.length > 0) {
      dispatch({
        type: ReduxActionTypes.DELETE_APPLICATION_INIT,
        payload: {
          applicationId: applicationId,
        },
      });
      history.push(APPLICATIONS_URL);
    } else {
      toast.show("删除应用时发生了错误", {
        kind: "error",
      });
    }
  };

  return currentApplication?.type !== "module"
    ? ([
        {
          text: "首页",
          onClick: () => history.replace(APPLICATIONS_URL),
          type: MenuTypes.MENU,
          isVisible: true,
        },
        {
          text: "divider_1",
          type: MenuTypes.MENU_DIVIDER,
          isVisible: true,
        },
        {
          text: "重命名",
          onClick: editMode,
          type: MenuTypes.MENU,
          isVisible: true,
        },
        {
          text: "克隆应用",
          onClick: () => setForkApplicationModalOpen(true),
          type: MenuTypes.MENU,
          isVisible: isApplicationIdPresent && hasEditPermission,
        },
        {
          text: "导出应用",
          onClick: exportAppAsJSON,
          type: MenuTypes.MENU,
          isVisible: isApplicationIdPresent && hasExportPermission,
        },
        hasDeleteApplicationPermission(currentApplication?.userPermissions) && {
          text: "删除应用",
          confirmText: "确定吗?",
          onClick: deleteApplication,
          type: MenuTypes.RECONFIRM,
          isVisible: isApplicationIdPresent,
          style: { color: Colors.ERROR_RED },
        },
        {
          text: "divider_2",
          type: MenuTypes.MENU_DIVIDER,
          isVisible: true,
        },
        !isSideBySideFlagEnabled && {
          text: "Edit",
          type: MenuTypes.PARENT,
          isVisible: true,
          children: [
            {
              text: "撤回",
              labelElement: undoShortCut(),
              onClick: () => dispatch(undoAction()),
              type: MenuTypes.MENU,
              isVisible: true,
            },
            {
              text: "重做",
              labelElement: redoShortCut(),
              onClick: () => dispatch(redoAction()),
              type: MenuTypes.MENU,
              isVisible: true,
            },
          ],
        },
        {
          text: "帮助",
          type: MenuTypes.PARENT,
          isVisible: isHelpDocEnabled,
          children: [
            {
              text: "Github",
              onClick: () =>
                openExternalLink("https://github.com/cloudtogo/pageplug"),
              type: MenuTypes.MENU,
              isVisible: true,
              isOpensNewWindow: true,
            },
            {
              text: "文档",
              onClick: () => openExternalLink(DOCS_BASE_URL),
              type: MenuTypes.MENU,
              isVisible: true,
              isOpensNewWindow: true,
            },
          ],
        },
      ].filter(Boolean) as MenuItemData[])
    : ([
        {
          text: "首页",
          onClick: () => history.replace(APPLICATIONS_URL),
          type: MenuTypes.MENU,
          isVisible: true,
        },
        hasDeleteApplicationPermission(currentApplication?.userPermissions) && {
          text: "删除模块",
          confirmText: "确定吗?",
          onClick: deleteApplication,
          type: MenuTypes.RECONFIRM,
          isVisible: isApplicationIdPresent,
          style: { color: Colors.ERROR_RED },
        },
      ].filter(Boolean) as MenuItemData[]);
};
