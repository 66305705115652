import _ from "lodash";
import { useSelector } from "react-redux";
import { getInstanceName } from "ee/selectors/tenantSelectors";
import Api from "api/Api";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getHtmlPageTitle = (instanceName: string) => {
  return "Pageplug";
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useHtmlPageTitle = () => {
  const instanceName = useSelector(getInstanceName);
  return instanceName;
};

export const isCEMode = () => {
  return true;
};

export const ThirdPartyLogin = (
  requestUrl: any,
  param?: any,
  config?: any,
): void => {
  Api.get(requestUrl, param, config).then(({ data }) => {
    const url = data.redirectUrl;
    const newWindow: any = window.open(url, "_self");
    newWindow.focus();
  });
};
export const getPageTitle = (
  displayName?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  titleSuffix?: string,
) => {
  return `${displayName ? `${displayName} | ` : ""}Pageplug`;
};

// TODO: Remove this function once we have a better way to handle this
// get only the part of the url after the domain name
export const to = (url: string) => {
  const path = _.drop(
    url
      .toString()
      .replace(/([a-z])?:\/\//, "$1")
      .split("/"),
  ).join("/");
  return `/${path}`;
};

export const defaultOptionSelected = "";

export function getSnippetUrl(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPublicApp: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  method: string,
) {
  return url;
}
