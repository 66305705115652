import {
  Breadcrumb,
  Button,
  Dropdown,
  Input,
  MenuProps,
  message,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import { SearchProps } from "antd/es/input";
import {
  copyModule,
  deleteModule,
  getAllFiders,
  getFolderModules,
  updateModuleName,
} from "ce/actions/modulesActions";
import {
  getFolderModulesState,
  getFoldersState,
} from "ce/selectors/moduleSelectors";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { EllipsisOutlined } from "@ant-design/icons";
import styled from "styled-components";
import AddModuleModal from "./addModule";
import { isEmpty } from "lodash";
import MoveModuleModal from "./moveModule";

const AntdTable = styled(Table)`
  .ant-table {
    font-size: 13px;
    border: none;
  }

  .ant-table-column-title {
    font-size: 14px;
    font-weight: 400;
  }

  .ant-table-thead {
    margin-bottom: 4px;
    position: relative;
  }

  .ant-table-thead > tr > th {
    color: #8b8fa3;
    background: #ffffff;
    padding-bottom: 9px;
    border: none;
    //border-bottom: 1px solid #8b8fa3; // divider line for the table header
    padding: 3px 12px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-table-thead > tr > th::before {
    display: none;
  }

  .ant-table-measure-row {
    height: 4px !important;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-row {
    cursor: pointer;
  }

  tr {
    position: relative;
  }

  .ant-table-thead:after {
    content: "";
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 0;
    background: #e1e3eb;
    height: 1px;
    border-radius: 10px;
  }

  .ant-table-row:after {
    content: "";
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 0;
    background: #f0f0f0;
    height: 1px;
    border-radius: 10px;
  }

  .ant-table-row:hover .home-datasource-edit-button {
    opacity: 1;
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  td.ant-table-column-sort {
    background: unset;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #f5f7fa;
  }

  .ant-table-cell {
    height: 56px;
    margin-left: 24px;
    max-width: 184px; // actual width + padding right
  }

  /** the two-side shadow when scrollbars exist */

  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    box-shadow: none;
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }

  .ant-table-tbody > tr > td {
    padding: 16px 12px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: red;
  }
  .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: red;
  }
`;

const SubColumnCell = styled.div`
  color: #8b8fa3;
`;

const StyledApplicationModulesContainer = styled.div`
  margin-top: 48px;
  padding: 40px 25px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const CellWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledDropDownWrapper = styled.div`
  width: 25px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  .BreadcrumbItem {
    background: red;
  }
  &:hover {
    background-color: #e1e3eb;
    border-radius: 4px;
  }
`;

const { Search } = Input;

const { Text } = Typography;

const TypographyText = styled(Text)`
  margin: 0 !important;
  left: 0 !important;
  width: 100%;
`;

const items: MenuProps["items"] = [
  {
    label: "重命名",
    key: "1",
  },
  {
    label: "复制模块",
    key: "2",
  },
  {
    label: "导出JSON",
    key: "3",
  },
  {
    label: "移动到文件夹",
    key: "4",
  },
  {
    label: "删除",
    key: "5",
    danger: true,
  },
];

const FolderListDetail: React.FC<any> = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isMoveModalOpen, setMoveModalOpen] = useState({});
  const [isModuleModalOpen, setModuleModalOpen] = useState<boolean>(false);
  const [moduleNameEditingId, setModuleNameEditingId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams() as any;

  const folderModulesData = useSelector(getFolderModulesState);
  const foldersData = useSelector(getFoldersState);

  const currentFolder = useMemo(() => {
    if (!Array.isArray(foldersData)) {
      return undefined;
    }
    return foldersData.find(
      (it: Record<string, string>) => params.folderId === it.id,
    );
  }, [foldersData, params.folderId]);

  const error = (record) => {
    Modal.error({
      title: "确认删除吗？",
      content: "删除后该文件夹将无法找回",
      footer: (
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button key="back" onClick={() => Modal.destroyAll()}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              dispatch(deleteModule(record.id, params.folderId));
              Modal.destroyAll();
            }}
            style={{ marginLeft: 8 }}
          >
            确定
          </Button>
        </div>
      ),
    });
  };

  const getExportAppAPIRoute = (applicationId: string) => {
    let exportUrl = `/api/v1/module/export/${applicationId}`;
    return exportUrl;
  };

  const exportJSONFile = (record: { id: string; name: string }) => {
    // export api response comes with content-disposition header.
    // there is no straightforward way to handle it with axios/fetch
    const id = `t--export-module-link`;
    const existingLink = document.getElementById(id);
    existingLink && existingLink.remove();
    const link = document.createElement("a");
    const moduleId = record.id;
    link.href = getExportAppAPIRoute(moduleId);
    link.id = id;
    document.body.appendChild(link);
    // @ts-expect-error: Types are not available
    if (!window.Cypress) {
      link.click();
    }
    // react18的报错
    message.success(`${record.name}成功导出`);
    // messageApi.open({
    //   type: "success",
    //   content: "成功导出",
    // });
  };

  const colseModuleModal = () => {
    setModuleModalOpen(false);
  };

  const colseMoveModuleModal = () => {
    setMoveModalOpen({});
  };

  const handleMenuClick = (e, record) => {
    e.domEvent.stopPropagation();
    const actions: Record<string, () => void> = {
      "1": () => setModuleNameEditingId(record.id),
      "2": () => dispatch(copyModule(record.name, record.id, params.folderId)),
      "3": () => exportJSONFile(record),
      "4": () =>
        setMoveModalOpen({
          isVisible: true,
          ...record,
        }),
      "5": () => error(record),
    };

    const action = actions[e.key];
    if (action) {
      action();
    }
  };

  const menuProps = (record) => ({
    items,
    onClick: (e) => handleMenuClick(e, record),
  });

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => setName(value);

  useEffect(() => {
    dispatch(getFolderModules(name, params.folderId));
  }, [name]);

  useEffect(() => {
    dispatch(getAllFiders(""));
  }, []);

  return (
    <StyledApplicationModulesContainer>
      <StyledHeader>
        <StyledTitle>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: "我的空间",
                onClick: () => history.push("/modules"),
                className: "BreadcrumbItem",
              },
              {
                title: currentFolder ? currentFolder.name : "",
              },
            ]}
          />
        </StyledTitle>
        <div>
          <Search
            placeholder="请输入"
            onSearch={onSearch}
            style={{ width: 200, marginRight: "20px" }}
          />
          <Button type="primary" onClick={() => setModuleModalOpen(true)}>
            <Space>新建</Space>
          </Button>
        </div>
      </StyledHeader>
      <AntdTable
        style={{ padding: "0 24px 80px", color: "#8B8FA3" }}
        tableLayout={"auto"}
        pagination={false}
        columns={[
          {
            title: "名称",
            dataIndex: "name",
            ellipsis: true,
            render: (_, record) => {
              return (
                <TypographyText
                  title={record.name}
                  editable={{
                    enterIcon: null,
                    tooltip: false,
                    editing: moduleNameEditingId === record.id,
                    icon: null,
                    triggerType: ["text"],
                    onChange: (value) => {
                      if (!value.trim()) {
                        message.warning(`不能为空`);
                        // messageApi.open({
                        //   type: "warning",
                        //   content: "不能为空",
                        // });
                        return;
                      }
                      setModuleNameEditingId("");
                      dispatch(
                        updateModuleName(value, record.id, params.folderId),
                      );
                    },
                  }}
                >
                  <span
                    title={record.name}
                    className="truncate max-w-[160px] block"
                  >
                    {record.name}
                  </span>
                </TypographyText>
              );
            },
          },
          {
            title: "创建时间",
            dataIndex: "createdAt",
            ellipsis: true,
            render: (time) => {
              return time ? (
                <div
                  title={time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ""}
                >
                  {moment(time).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              ) : (
                ""
              );
            },
          },
          {
            title: "最近修改时间",
            dataIndex: "updatedAt",
            ellipsis: true,
            width: "192px",
            render: (time) => {
              return time ? (
                <div
                  title={time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ""}
                >
                  {moment(time).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              ) : (
                ""
              );
            },
          },
          {
            title: "操作",
            dataIndex: "operation",
            width: "298px",
            align: "right",
            render: (text, record) => {
              return (
                <CellWrapper>
                  <StyledDropDownWrapper onClick={(e) => e.stopPropagation()}>
                    <Dropdown menu={menuProps(record)}>
                      <Space>
                        <EllipsisOutlined />
                      </Space>
                    </Dropdown>
                  </StyledDropDownWrapper>
                </CellWrapper>
              );
            },
          },
        ]}
        dataSource={folderModulesData}
      ></AntdTable>

      {isModuleModalOpen && (
        <AddModuleModal
          openModal={isModuleModalOpen}
          closeModal={colseModuleModal}
        ></AddModuleModal>
      )}

      {!isEmpty(isMoveModalOpen) && (
        <MoveModuleModal
          openModal={isMoveModalOpen}
          closeModal={colseMoveModuleModal}
        />
      )}
    </StyledApplicationModulesContainer>
  );
};

export default FolderListDetail;
