import type { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { TriggerMeta } from "ee/sagas/ActionExecution/ActionExecutionSagas";
import { call, put, spawn, take, select } from "redux-saga/effects";
import { logActionExecutionError } from "sagas/ActionExecution/errorUtils";
import type { Channel } from "redux-saga";
import { channel } from "redux-saga";
import { evalWorker } from "sagas/EvaluationsSaga";
import type {
  TWindowMessageListenerDescription,
  TUnlistenWindowMessageDescription,
} from "workers/Evaluation/fns/messageListenerFns";
import _find from "lodash/find";
import log from "loglevel";

let successChannel: Channel<any> | null = null;

export let messageEventMap = new Map<
  number | string,
  (event: MessageEvent<any>) => void
>();

function* callbackHandler(listenerId?: string) {
  let payload: GeolocationPosition;
  if (!successChannel) return;
  while ((payload = yield take(successChannel))) {
    if (listenerId) {
      log.info(
        "[[ MESSAGE ]]\t[ saga ] (3) 通知 worker 执行",
        listenerId,
        payload,
      );
      yield call(evalWorker.ping, { data: payload }, listenerId);
    }
  }
}

let watchId: number | undefined;
export function* windowMessageListenerSaga(
  action: TWindowMessageListenerDescription,
  _: EventType,
  triggerMeta?: TriggerMeta,
) {
  const { payload: actionPayload } = action;
  successChannel = channel<any>();
  yield spawn(callbackHandler, actionPayload.listenerId);

  // 成功的回调
  if (window && actionPayload.domain) {
    const messageHandler = (e: any) => {
      const { data, origin, source } = e;
      if (source === window.parent) {
        log.info(
          "[[ MESSAGE ]]\t[ saga ] (...) 当前的WML监听器收到了message",
          `${actionPayload.listenerId}`,
        );
        if (actionPayload.domain !== origin) {
          console.warn(
            `您指定监听的域:${actionPayload.domain}, 当前消息来源域:${origin}`,
          );
          return;
        }
        watchId = actionPayload.listenerId;
        successChannel?.put(data);
      }
    };
    const mhStr: string = String(messageHandler);
    if (messageEventMap.has(mhStr)) {
      //如果这个监听以前加载过了，就remove它重新加。
      log.info(
        "[[ MESSAGE ]]\t[ saga ] (1) 如果这个监听以前加载过了，就remove它重新加。",
      );
      window.removeEventListener(
        "message",
        messageEventMap.get(mhStr) as (
          this: Window,
          ev: MessageEvent<any>,
        ) => any,
      );
      messageEventMap.set(String(messageHandler), messageHandler);
      return window.addEventListener("message", messageHandler);
    }
    log.info("[[ MESSAGE ]]\t[ saga ] (1) 添加window 的 message 监听器");
    messageEventMap.set(String(messageHandler), messageHandler);
    // 添加message监听器
    return window.addEventListener("message", messageHandler);
  }
}

export function* unlistenWindowMessageSaga(
  action: TUnlistenWindowMessageDescription,
  _: EventType,
  triggerMeta?: TriggerMeta,
) {
  const { payload: actionPayload } = action;
  log.info(
    `[[ MESSAGE ]]\t[ saga ] (1) window 需要取消-对${actionPayload.domain}的监听`,
  );
  successChannel?.close();
  // window.removeEventListener("message", WMessageListener)
  // if (watchId === undefined) {
  //   yield call(
  //     logActionExecutionError,
  //     "No window messsage listener active",
  //     true,
  //   );
  //   return;
  // }
  // 清除定时器
  watchId = undefined;
}
