import { createImmerReducer } from "utils/ReducerUtils";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import type { Operation } from "constants/AppConstants";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { omit, isUndefined, isEmpty } from "lodash";
import equal from "fast-deep-equal";

const initialState: OperationReduxState = {
  logs: [],
  isOpen: false,
};

// check the last message from the current log and update the occurrence count
const removeRepeatedLogsAndMerge = (
  currentLogs: Operation[],
  incomingLogs: Operation[],
) => {
  const outputArray = incomingLogs.reduce(
    (acc: Operation[], incomingLog: Operation) => {
      acc.push(incomingLog);
      return acc;
    },
    currentLogs,
  );

  return outputArray;
};

const operationReducer = createImmerReducer(initialState, {
  [ReduxActionTypes.OPERATION_LOG]: (
    state: OperationReduxState,
    action: ReduxAction<Operation[]>,
  ) => {
    // state.logs = [...state.logs, ...action.payload];
    state.logs = removeRepeatedLogsAndMerge(state.logs, action.payload);
  },
  [ReduxActionTypes.CLEAR_OPERATION_LOGS]: (state: OperationReduxState) => {
    state.logs = [];
  },
  [ReduxActionTypes.SHOW_OPERATION]: (
    state: OperationReduxState,
    action: ReduxAction<boolean | undefined>,
  ) => {
    // what for this？
    state.isOpen = isUndefined(action.payload) ? !state.isOpen : action.payload;
  },
});

export interface OperationReduxState {
  logs: Operation[];
  isOpen: boolean;
}

export default operationReducer;
