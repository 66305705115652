import {
  Button,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  copyModule,
  deleteModule,
  updateModuleName,
} from "ce/actions/modulesActions";
import MoveModuleModal from "./components/moveModule";
import { isEmpty } from "lodash";
import React from "react";
import { Dispatch } from "redux";
import { AnyObject } from "antd/es/_util/type";
import history from "utils/history";
import { builderURL, viewerURL } from "ee/RouteBuilder";
import { updateApplication } from "ee/actions/applicationActions";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { getExportAppAPIRoute } from "ee/constants/ApiConstants";
const AntdTable = styled(Table)`
  .ant-table {
    font-size: 13px;
    border: none;
  }

  .ant-table-column-title {
    font-size: 14px;
    font-weight: 400;
  }

  .ant-table-thead {
    margin-bottom: 4px;
    position: relative;
  }

  .ant-table-thead > tr > th {
    color: #8b8fa3;
    background: #ffffff !important;
    padding-bottom: 9px;
    border: none;
    //border-bottom: 1px solid #8b8fa3; // divider line for the table header
    padding: 3px 12px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
  }

  .ant-table-thead > tr > th::before {
    display: none;
  }

  .ant-table-measure-row {
    height: 4px !important;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .ant-table-row {
    cursor: pointer;
  }

  tr {
    position: relative;
  }

  .ant-table-thead:after {
    content: "";
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 0;
    background: #e1e3eb;
    height: 1px;
    border-radius: 10px;
  }

  .ant-table-row:after {
    content: "";
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 0;
    background: #f0f0f0;
    height: 1px;
    border-radius: 10px;
  }

  .ant-table-row:hover .home-datasource-edit-button {
    opacity: 1;
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  td.ant-table-column-sort {
    background: unset;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #f5f7fa;
  }

  .ant-table-cell {
    height: 56px;
    margin-left: 24px;
    max-width: 184px; // actual width + padding right
  }

  /** the two-side shadow when scrollbars exist */

  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    box-shadow: none;
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }

  .ant-table-tbody > tr > td {
    padding: 16px 12px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: red;
  }
  .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: red;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: red !important;
  }
`;

const StyledDropDownWrapper = styled.div`
  width: 25px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    background-color: #e1e3eb;
    border-radius: 4px;
  }
`;

const CellWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const items: MenuProps["items"] = [
  {
    label: "重命名",
    key: "1",
  },
  {
    label: "复制模块",
    key: "2",
  },
  {
    label: "导出JSON",
    key: "3",
  },
  // {
  //   label: "移动到文件夹",
  //   key: "4",
  // },
  {
    label: "删除",
    key: "5",
    danger: true,
  },
];

const { Text } = Typography;

const TypographyText = styled(Text)`
  margin: 0 !important;
  left: 0 !important;
  width: 100%;
`;

const StyledSubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

interface EditableTextProps {
  record: Record<string, any>; // 假设 record 的类型是 Record<string, any>
  moduleNameEditing: boolean;
  setModuleNameEditing: any;
  dispatch: Dispatch<any>;
  updateModuleName: (
    name: string,
    id: string,
    folderId?: string | undefined,
  ) => any;
}

const ModulesList: React.FC<any> = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isMoveModalOpen, setMoveModalOpen] = useState({});
  const [moduleNameEditingId, setModuleNameEditingId] = useState<string>("");
  const dispatch = useDispatch();

  const error = (record: { id: string }) => {
    Modal.error({
      title: "确认删除吗？",
      content: "删除后该模块将无法找回",
      footer: (
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button key="back" onClick={() => Modal.destroyAll()}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              // dispatch(deleteModule(record.id, ""));
              dispatch({
                type: ReduxActionTypes.DELETE_APPLICATION_INIT,
                payload: {
                  applicationId: record.id,
                },
              });
              Modal.destroyAll();
            }}
            style={{ marginLeft: 8 }}
          >
            确定
          </Button>
        </div>
      ),
    });
  };

  // const getExportAppAPIRoute = (applicationId: string) => {
  //   let exportUrl = `/api/v1/module/export/${applicationId}`;
  //   return exportUrl;
  // };

  const exportJSONFile = (record: {
    id: string;
    name: string;
    type: string;
    gitApplicationMetadata: any;
  }) => {
    // export api response comes with content-disposition header.
    // there is no straightforward way to handle it with axios/fetch
    const id = `t--export-module-link`;
    const existingLink = document.getElementById(id);
    existingLink && existingLink.remove();
    const link = document.createElement("a");
    const moduleId = record.id;
    const branchName = record?.gitApplicationMetadata?.branchName;
    link.href = getExportAppAPIRoute(moduleId, record.type, branchName);
    link.id = id;
    document.body.appendChild(link);
    // @ts-expect-error: Types are not available
    if (!window.Cypress) {
      link.click();
    }
    message.success(`${record.name}成功导出`);
  };

  const colseModuleModal = () => {
    setMoveModalOpen({});
  };

  const handleMenuClick = (
    e: { domEvent: { stopPropagation: () => void }; key: string | number },
    record,
  ) => {
    e.domEvent.stopPropagation();
    const actions: Record<string, () => void> = {
      "1": () => setModuleNameEditingId(record.id),
      "2": () =>
        dispatch({
          type: ReduxActionTypes.FORK_APPLICATION_INIT,
          payload: {
            applicationId: record.id,
            workspaceId: record?.workspaceId,
            editMode: false,
          },
        }),
      "3": () => exportJSONFile(record),
      "4": () =>
        setMoveModalOpen({
          isVisible: true,
          ...record,
        }),
      "5": () => error(record),
    };

    const action = actions[e.key];
    if (action) {
      action();
    }
  };

  const menuProps = (record: AnyObject) => ({
    items,
    onClick: (e: any) => handleMenuClick(e, record),
  });

  return (
    <div>
      <StyledSubTitle>模块</StyledSubTitle>
      <AntdTable
        style={{ padding: "0 24px 80px", color: "#8B8FA3" }}
        tableLayout={"auto"}
        pagination={false}
        onRow={(record) => ({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!record?.defaultPageId) {
              return;
            }
            history.push(
              builderURL({
                pageId: record.defaultPageId,
                // {},
              }),
            );
          },
        })}
        columns={[
          {
            title: "名称",
            dataIndex: "name",
            ellipsis: true,
            render: (_, record) => {
              return (
                <div
                  onClick={(e) => {
                    // 阻止事件冒泡
                    e.stopPropagation();
                  }}
                >
                  <TypographyText
                    title={record.name}
                    editable={{
                      enterIcon: null,
                      tooltip: false,
                      editing: moduleNameEditingId === record.id,
                      icon: null,
                      triggerType: ["text"],
                      onChange: (value) => {
                        if (!value.trim()) {
                          message.warning(`不能为空`);
                          return;
                        }
                        setModuleNameEditingId("");
                        dispatch(updateApplication(record.id, { name: value }));
                        // dispatch(updateModuleName(value, record.id, ""));
                      },
                    }}
                  >
                    <span
                      title={record.name}
                      className="truncate max-w-[160px] block"
                      onClick={(e) => {
                        // 阻止事件冒泡
                        e.stopPropagation();
                      }}
                    >
                      {record.name}
                    </span>
                  </TypographyText>
                </div>
              );
            },
          },
          {
            title: "创建时间",
            dataIndex: "createdAt",
            ellipsis: true,
            render: (time) => {
              return time ? (
                <div
                  title={time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ""}
                >
                  {moment(time).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              ) : (
                ""
              );
            },
          },
          {
            title: "最近修改时间",
            dataIndex: "modifiedAt",
            ellipsis: true,
            width: "192px",
            render: (time) => {
              return time ? (
                <div
                  title={time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ""}
                >
                  {moment(time).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              ) : (
                ""
              );
            },
          },
          {
            title: "操作",
            dataIndex: "operation",
            width: "298px",
            align: "right",
            render: (text, record) => {
              return (
                <CellWrapper>
                  <StyledDropDownWrapper onClick={(e) => e.stopPropagation()}>
                    <Dropdown menu={menuProps(record)}>
                      <Space>
                        <EllipsisOutlined />
                      </Space>
                    </Dropdown>
                  </StyledDropDownWrapper>
                </CellWrapper>
              );
            },
          },
        ]}
        dataSource={props.modulesData}
      ></AntdTable>
      {!isEmpty(isMoveModalOpen) && (
        <MoveModuleModal
          openModal={isMoveModalOpen}
          closeModal={colseModuleModal}
        />
      )}
    </div>
  );
};

export default ModulesList;
