import {
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Select,
  Space,
} from "antd";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  CloseCircleFilled,
  createFromIconfontCN,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { moveModule } from "ce/actions/modulesActions";
import { useParams } from "react-router";
import { getFoldersState } from "ce/selectors/moduleSelectors";

const StyledFormWrapper = styled.div`
  margin-top: 25px;
  .ant-select-clear-icon {
    display: none;
  }
`;

const MoveModuleModal: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const foldersData = useSelector(getFoldersState);
  const dispatch = useDispatch();
  const params = useParams() as any;

  const foldersOptions = useMemo(() => {
    return foldersData.map((it: Record<string, string>) => ({
      label: it.name,
      value: it.id,
    }));
  }, [foldersData]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const findFolder = foldersOptions.find(
          (it: Record<string, string>) => it.value === values.id,
        );
        const request = {
          id: props.openModal.id,
          name: props.openModal.name,
          folderId: findFolder.value,
          folderName: findFolder.label,
        };
        dispatch(moveModule(request, params.folderId || ""));
        props.closeModal();
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    props.closeModal();
  };

  const onValuesChange = (changedValues, allValues) => {
    setIsDisabled(isEmpty(allValues.id));
  };

  return (
    <div>
      <Modal
        title="移动模块"
        open={props.openModal.isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isDisabled }}
      >
        <StyledFormWrapper>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            labelCol={{ span: 6 }}
            autoComplete="off"
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label="文件夹名称"
              name="id"
              rules={[{ required: true, message: "请选择文件夹" }]}
            >
              <Select
                allowClear
                options={foldersOptions}
                showArrow={true}
                inputIcon={<DownOutlined className="ant-select-suffix" />}
                clearIcon={<CloseCircleFilled />}
              />
            </Form.Item>
          </Form>
        </StyledFormWrapper>
      </Modal>
    </div>
  );
};

export default MoveModuleModal;
