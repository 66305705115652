import { call, put, race, select, take } from "redux-saga/effects";
import type {
  ReduxAction,
  ReduxActionWithPromise,
} from "ee/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import { reset } from "redux-form";
import type {
  CreateUserRequest,
  CreateUserResponse,
  ForgotPasswordRequest,
  VerifyTokenRequest,
  TokenPasswordUpdateRequest,
  UpdateUserRequest,
  LeaveWorkspaceRequest,
  VerifyCurrentUserRequest,
} from "ee/api/UserApi";
import { AUTH_LOGIN_URL, matchBuilderPath, SETUP } from "constants/routes";
import UserApi from "ee/api/UserApi";
import history from "utils/history";
import { removeTokenParameter } from "utils/URLUtils";
import type { ApiResponse } from "api/ApiResponses";
import {
  validateResponse,
  getResponseErrorMessage,
  callAPI,
} from "sagas/ErrorSagas";
import _get from "lodash/get";
import {
  logoutUserSuccess,
  logoutUserError,
  verifyInviteSuccess,
  verifyInviteError,
  invitedUserSignupError,
  invitedUserSignupSuccess,
  fetchFeatureFlagsSuccess,
  fetchFeatureFlagsError,
  fetchProductAlertSuccess,
  fetchProductAlertFailure,
  fetchFeatureFlagsInit,
} from "actions/userActions";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { INVITE_USERS_TO_WORKSPACE_FORM } from "ee/constants/forms";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";
import type { User } from "constants/userConstants";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import {
  flushErrorsAndRedirect,
  safeCrashAppRequest,
} from "actions/errorActions";
// import localStorage from "utils/localStorage";
import log from "loglevel";

import {
  getCurrentUser,
  getFeatureFlagsFetched,
} from "selectors/usersSelectors";
import {
  initAppLevelSocketConnection,
  initPageLevelSocketConnection,
} from "actions/websocketActions";
import {
  getEnableFirstTimeUserOnboarding,
  getFirstTimeUserOnboardingApplicationIds,
  getFirstTimeUserOnboardingIntroModalVisibility,
} from "utils/storage";
import { initializeAnalyticsAndTrackers } from "utils/AppsmithUtils";
import { getAppsmithConfigs } from "ee/configs";
import { getSegmentState } from "selectors/analyticsSelectors";
import {
  segmentInitUncertain,
  segmentInitSuccess,
} from "actions/analyticsActions";
import type { SegmentState } from "reducers/uiReducers/analyticsReducer";
import type { FeatureFlags } from "ee/entities/FeatureFlag";
import { DEFAULT_FEATURE_FLAG_VALUE } from "ee/entities/FeatureFlag";
import UsagePulse from "usagePulse";
import { toast } from "design-system";
import { isAirgapped } from "ee/utils/airgapHelpers";
const { inCloudOS } = getAppsmithConfigs();
import type {
  ProductAlert,
  ProductAlertConfig,
} from "reducers/uiReducers/usersReducer";
import { selectFeatureFlags } from "ee/selectors/featureFlagsSelectors";
import { getFromServerWhenNoPrefetchedResult } from "sagas/helper";
import { message } from "antd";

export function* createUserSaga(
  action: ReduxActionWithPromise<CreateUserRequest>,
) {
  const { email, password, reject, resolve } = action.payload;
  try {
    const request: CreateUserRequest = { email, password };
    const response: CreateUserResponse = yield callAPI(
      UserApi.createUser,
      request,
    );
    //TODO(abhinav): DRY this
    const isValidResponse: boolean = yield validateResponse(response);
    if (!isValidResponse) {
      const errorMessage = getResponseErrorMessage(response);
      yield call(reject, { _error: errorMessage });
    } else {
      //@ts-expect-error: response is of type unknown
      const { email, id, name } = response.data;
      yield put({
        type: ReduxActionTypes.CREATE_USER_SUCCESS,
        payload: {
          email,
          name,
          id,
        },
      });
      yield call(resolve);
    }
  } catch (error) {
    yield call(reject, { _error: (error as Error).message });
    yield put({
      type: ReduxActionErrorTypes.CREATE_USER_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* createSettingsUserSaga(
  action: ReduxActionWithPromise<CreateUserRequest>,
) {
  const { email, password, reject, resolve } = action.payload;
  try {
    const request: CreateUserRequest = { email, password };
    const response: CreateUserResponse = yield callAPI(
      UserApi.createSettingsUser,
      request,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (!isValidResponse) {
      const errorMessage = getResponseErrorMessage(response);
      yield call(reject, { _error: errorMessage });
    } else {
      yield put({
        type: ReduxActionTypes.CREATE_SETTINGS_USER_SUCCESS,
        payload: response.data,
      });
      message.success("添加用户成功");
      yield call(resolve);
    }
  } catch (error) {
    yield call(reject, { _error: (error as Error).message });
  }
}

export function* waitForSegmentInit(skipWithAnonymousId: boolean) {
  return;
  if (skipWithAnonymousId && AnalyticsUtil.getAnonymousId()) return;
  const currentUser: User | undefined = yield select(getCurrentUser);
  const segmentState: SegmentState | undefined = yield select(getSegmentState);
  const appsmithConfig = getAppsmithConfigs();

  if (
    currentUser?.enableTelemetry &&
    appsmithConfig.segment.enabled &&
    !segmentState
  ) {
    yield race([
      take(ReduxActionTypes.SEGMENT_INITIALIZED),
      take(ReduxActionTypes.SEGMENT_INIT_UNCERTAIN),
    ]);
  }
}

export function* getCurrentUserSaga(action?: {
  payload?: { userProfile?: ApiResponse };
}) {
  const userProfile = action?.payload?.userProfile;
  try {
    PerformanceTracker.startAsyncTracking(
      PerformanceTransactionName.USER_ME_API,
    );
    const newUrlObject = new URLSearchParams(location.search);
    const ssoToken = newUrlObject.get("kmToken") || ""; // keymantech url的token 参数
    let response: ApiResponse;
    if (
      _get(window, "PAGEPLUG_KEYMANTECH_BASE_URL", "") &&
      _get(window, "PAGEPLUG_KEYMANTECH_DEPLOY", false) &&
      ssoToken
    ) {
      const currentUrl = `${window.location.href}`;
      const newCurrentUrl = removeTokenParameter(currentUrl);

      // 认证需要带token参数
      const request: VerifyCurrentUserRequest = { token: ssoToken };
      response = yield call(
        getFromServerWhenNoPrefetchedResult,
        userProfile,
        () => call(UserApi.getCurrentUser, request),
      );
      if (_get(response.data, "isAnonymous")) {
        // 匿名用户
        window.location.href = `${_get(
          window,
          "PAGEPLUG_KEYMANTECH_BASE_URL",
          "",
        )}/login?redirectUrl=${encodeURIComponent(newCurrentUrl)}`;
        return;
      }
    } else {
      response = yield call(
        getFromServerWhenNoPrefetchedResult,
        userProfile,
        () => call(UserApi.getCurrentUser),
      );
    }
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      if (
        inCloudOS &&
        !_get(response.data, "cloudOSLogged") &&
        matchBuilderPath(window.location.pathname)
      ) {
        const currentUrl = `${window.location.href}`;
        const newCurrentUrl = removeTokenParameter(currentUrl);
        const ruri = `${_get(window, "CLOUDOS_LOGIN_URL", "")}?_r=${encodeURIComponent(newCurrentUrl)}`;
        window.location.href = ruri;
        return;
      }
      yield put({
        type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.USER_ME_API,
      { failed: true },
    );
    yield put({
      type: ReduxActionErrorTypes.FETCH_USER_DETAILS_ERROR,
      payload: {
        error,
      },
    });

    yield put(safeCrashAppRequest(error));
  }
}

export function* runUserSideEffectsSaga() {
  return;
  const currentUser: User = yield select(getCurrentUser);
  const { enableTelemetry } = currentUser;
  const isAirgappedInstance = isAirgapped();
  if (enableTelemetry) {
    const promise = initializeAnalyticsAndTrackers();

    if (promise instanceof Promise) {
      const result: boolean = yield promise;

      if (result) {
        yield put(segmentInitSuccess());
      } else {
        yield put(segmentInitUncertain());
      }
    }
  }

  if (!currentUser.isAnonymous && currentUser.username !== ANONYMOUS_USERNAME) {
    enableTelemetry && AnalyticsUtil.identifyUser(currentUser);
  }

  const isFFFetched: boolean = yield select(getFeatureFlagsFetched);
  if (!isFFFetched) {
    yield call(fetchFeatureFlagsInit);
    yield take(ReduxActionTypes.FETCH_FEATURE_FLAGS_SUCCESS);
  }

  const featureFlags: FeatureFlags = yield select(selectFeatureFlags);

  const isGACEnabled = featureFlags?.license_gac_enabled;

  const isFreeLicense = !isGACEnabled;

  if (!isAirgappedInstance) {
    // We need to stop and start tracking activity to ensure that the tracking from previous session is not carried forward
    UsagePulse.stopTrackingActivity();
    UsagePulse.startTrackingActivity(
      enableTelemetry && getAppsmithConfigs().segment.enabled,
      currentUser?.isAnonymous ?? false,
      isFreeLicense,
    );
  }

  yield put(initAppLevelSocketConnection());
  yield put(initPageLevelSocketConnection());

  if (currentUser.emptyInstance) {
    history.replace(SETUP);
  }

  PerformanceTracker.stopAsyncTracking(PerformanceTransactionName.USER_ME_API);
}

export function* forgotPasswordSaga(
  action: ReduxActionWithPromise<ForgotPasswordRequest>,
) {
  const { email, reject, resolve, verifyCode } = action.payload;

  try {
    const request: ForgotPasswordRequest = { email, verifyCode };
    const response: ApiResponse = yield callAPI(
      UserApi.forgotPassword,
      request,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (!isValidResponse) {
      const errorMessage: string | undefined =
        yield getResponseErrorMessage(response);
      yield call(reject, { _error: errorMessage });
    } else {
      yield put({
        type: ReduxActionTypes.FORGOT_PASSWORD_SUCCESS,
      });
      yield call(resolve);
    }
  } catch (error) {
    log.error(error);
    yield call(reject, { _error: (error as Error).message });
    yield put({
      type: ReduxActionErrorTypes.FORGOT_PASSWORD_ERROR,
    });
  }
}

export function* resetPasswordSaga(
  action: ReduxActionWithPromise<TokenPasswordUpdateRequest>,
) {
  const { email, password, reject, resolve, token } = action.payload;
  try {
    const request: TokenPasswordUpdateRequest = {
      email,
      password,
      token,
    };
    const response: ApiResponse = yield callAPI(UserApi.resetPassword, request);
    const isValidResponse: boolean = yield validateResponse(response);
    if (!isValidResponse) {
      const errorMessage: string | undefined =
        yield getResponseErrorMessage(response);
      yield call(reject, { _error: errorMessage });
    } else {
      yield put({
        type: ReduxActionTypes.RESET_USER_PASSWORD_SUCCESS,
      });
      yield call(resolve);
    }
  } catch (error) {
    log.error(error);
    yield call(reject, { _error: (error as Error).message });
    yield put({
      type: ReduxActionErrorTypes.RESET_USER_PASSWORD_ERROR,
      payload: {
        error: (error as Error).message,
      },
    });
  }
}

export function* invitedUserSignupSaga(
  action: ReduxActionWithPromise<TokenPasswordUpdateRequest>,
) {
  const { email, password, reject, resolve, token } = action.payload;
  try {
    const request: TokenPasswordUpdateRequest = { email, password, token };
    const response: ApiResponse = yield callAPI(
      UserApi.confirmInvitedUserSignup,
      request,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (!isValidResponse) {
      const errorMessage: string | undefined =
        yield getResponseErrorMessage(response);
      yield call(reject, { _error: errorMessage });
    } else {
      yield put(invitedUserSignupSuccess());
      yield call(resolve);
    }
  } catch (error) {
    log.error(error);
    yield call(reject, { _error: (error as Error).message });
    yield put(invitedUserSignupError(error));
  }
}

interface InviteUserPayload {
  email: string;
  permissionGroupId: string;
}

export function* inviteUser(payload: InviteUserPayload, reject: any) {
  const response: ApiResponse = yield callAPI(UserApi.inviteUser, payload);
  const isValidResponse: boolean = yield validateResponse(response);
  if (!isValidResponse) {
    let errorMessage = `${payload.email}:  `;
    errorMessage += getResponseErrorMessage(response);
    yield call(reject, { _error: errorMessage });
  }
  yield;
}

export function* inviteUsers(
  action: ReduxActionWithPromise<{
    data: {
      usernames: string[];
      workspaceId: string;
      permissionGroupId: string;
    };
  }>,
) {
  const { data, reject, resolve } = action.payload;
  try {
    const response: ApiResponse<{ id: string; username: string }[]> =
      yield callAPI(UserApi.inviteUser, {
        usernames: data.usernames,
        permissionGroupId: data.permissionGroupId,
      });
    const isValidResponse: boolean = yield validateResponse(response, false);
    if (!isValidResponse) {
      let errorMessage = `${data.usernames}:  `;
      errorMessage += getResponseErrorMessage(response);
      yield call(reject, { _error: errorMessage });
    }
    yield put({
      type: ReduxActionTypes.FETCH_ALL_USERS_INIT,
      payload: {
        workspaceId: data.workspaceId,
      },
    });
    const { data: responseData } = response;
    yield put({
      type: ReduxActionTypes.INVITED_USERS_TO_WORKSPACE,
      payload: {
        workspaceId: data.workspaceId,
        users: responseData.map((user: { id: string; username: string }) => ({
          userId: user.id,
          username: user.username,
          permissionGroupId: data.permissionGroupId,
        })),
      },
    });
    yield call(resolve);
    yield put(reset(INVITE_USERS_TO_WORKSPACE_FORM));
  } catch (error) {
    yield call(reject, { _error: (error as Error).message });
  }
}

export function* updateUserDetailsSaga(action: ReduxAction<UpdateUserRequest>) {
  try {
    const { email, intercomConsentGiven, name, proficiency, role, useCase } =
      action.payload;

    const response: ApiResponse = yield callAPI(UserApi.updateUser, {
      email,
      name,
      proficiency,
      role,
      useCase,
      intercomConsentGiven,
    });
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.UPDATE_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_USER_DETAILS_ERROR,
      payload: (error as Error).message,
    });
  }
}

export function* verifyResetPasswordTokenSaga(
  action: ReduxAction<VerifyTokenRequest>,
) {
  try {
    const request: VerifyTokenRequest = action.payload;
    const response: ApiResponse = yield call(
      UserApi.verifyResetPasswordToken,
      request,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse && response.data) {
      yield put({
        type: ReduxActionTypes.RESET_PASSWORD_VERIFY_TOKEN_SUCCESS,
      });
    } else {
      yield put({
        type: ReduxActionErrorTypes.RESET_PASSWORD_VERIFY_TOKEN_ERROR,
      });
    }
  } catch (error) {
    log.error(error);
    yield put({
      type: ReduxActionErrorTypes.RESET_PASSWORD_VERIFY_TOKEN_ERROR,
    });
  }
}

export function* verifyUserInviteSaga(action: ReduxAction<VerifyTokenRequest>) {
  try {
    const request: VerifyTokenRequest = action.payload;
    const response: ApiResponse = yield call(UserApi.verifyUserInvite, request);
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put(verifyInviteSuccess());
    }
  } catch (error) {
    log.error(error);
    yield put(verifyInviteError(error));
  }
}

export function* logoutSaga(action: ReduxAction<{ redirectURL: string }>) {
  try {
    const redirectUrl = action.payload?.redirectURL;
    const response: ApiResponse<{ redirectUrl?: string }> = yield call(
      UserApi.logoutUser,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      UsagePulse.stopTrackingActivity();
      AnalyticsUtil.reset();
      const currentUser: User | undefined = yield select(getCurrentUser);
      yield put(logoutUserSuccess(!!currentUser?.emptyInstance));
      // 退出重新请求下当前注册相关权限
      yield put({
        type: ReduxActionTypes.FETCH_CURRENT_TENANT_CONFIG,
        payload: {
          isBackgroundRequest: true,
        },
      });
      localStorage.clear();
      if (response.data && response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else if (redirectUrl && redirectUrl.startsWith("http")) {
        window.location.href = redirectUrl;
      } else {
        yield put(flushErrorsAndRedirect(redirectUrl || AUTH_LOGIN_URL));
      }
    }
  } catch (error) {
    log.error(error);
    yield put(logoutUserError(error));
  }
}

export function* waitForFetchUserSuccess() {
  const currentUser: string | undefined = yield select(getCurrentUser);
  if (!currentUser) {
    yield take(ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS);
  }
}

export function* removePhoto(
  action: ReduxAction<{ callback: (id: string) => void }>,
) {
  try {
    const response: ApiResponse = yield call(UserApi.deletePhoto);
    //@ts-expect-error: response is of type unknown
    const photoId = response.data?.profilePhotoAssetId; //get updated photo id of iploaded image
    if (action.payload.callback) action.payload.callback(photoId);
  } catch (error) {
    log.error(error);
  }
}

export function* updatePhoto(
  action: ReduxAction<{ file: File; callback: (id: string) => void }>,
) {
  try {
    const response: ApiResponse = yield call(UserApi.uploadPhoto, {
      file: action.payload.file,
    });
    //@ts-expect-error: response is of type unknown
    const photoId = response.data?.profilePhotoAssetId; //get updated photo id of iploaded image
    if (action.payload.callback) action.payload.callback(photoId);
  } catch (error) {
    log.error(error);
  }
}

export function* updateAsset(
  action: ReduxAction<{ file: File; callback: (id: string) => void }>,
) {
  try {
    const response: ApiResponse = yield call(UserApi.uploadAsset, {
      file: action.payload.file,
    });
    //@ts-expect-error: response is of type unknown
    const photoId: string = response?.data || ""; //get updated photo id of iploaded image
    if (action.payload.callback) action.payload.callback(photoId);
  } catch (error) {
    log.error(error);
  }
}

export function* fetchFeatureFlags(action?: {
  payload?: { featureFlags?: ApiResponse<FeatureFlags> };
}) {
  const featureFlags = action?.payload?.featureFlags;
  try {
    const response: ApiResponse<FeatureFlags> = yield call(
      getFromServerWhenNoPrefetchedResult,
      featureFlags,
      () => call(UserApi.fetchFeatureFlags),
    );

    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put(
        fetchFeatureFlagsSuccess({
          ...DEFAULT_FEATURE_FLAG_VALUE,
          ...response.data,
        }),
      );
    }
  } catch (error) {
    log.error(error);
    yield put(fetchFeatureFlagsError(error));
  }
}

export function* updateFirstTimeUserOnboardingSage() {
  const enable: string | null = yield getEnableFirstTimeUserOnboarding();

  if (enable) {
    const applicationId: string =
      yield getFirstTimeUserOnboardingApplicationIds() || "";
    const introModalVisibility: string | null =
      yield getFirstTimeUserOnboardingIntroModalVisibility();
    yield put({
      type: ReduxActionTypes.SET_ENABLE_FIRST_TIME_USER_ONBOARDING,
      payload: true,
    });
    yield put({
      type: ReduxActionTypes.SET_FIRST_TIME_USER_ONBOARDING_APPLICATION_ID,
      payload: applicationId,
    });
    yield put({
      type: ReduxActionTypes.SET_SHOW_FIRST_TIME_USER_ONBOARDING_MODAL,
      payload: introModalVisibility,
    });
  }
}

export function* leaveWorkspaceSaga(
  action: ReduxAction<LeaveWorkspaceRequest>,
) {
  try {
    const request: LeaveWorkspaceRequest = action.payload;
    const { workspaceId, type } = action.payload;
    const response: ApiResponse = yield call(UserApi.leaveWorkspace, request);
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.DELETE_WORKSPACE_SUCCESS,
        payload: workspaceId,
      });
      toast.show(type === "app" ? `成功退出应用组` : `成功退出模块组`, {
        kind: "success",
      });
      history.push("/applications");
    }
  } catch (error) {
    // do nothing as it's already handled globally
  }
}

export function* fetchProductAlertSaga(action?: {
  payload?: { productAlert?: ApiResponse<ProductAlert> };
}) {
  const productAlert = action?.payload?.productAlert;
  try {
    const response: ApiResponse<ProductAlert> = yield call(
      getFromServerWhenNoPrefetchedResult,
      productAlert,
      () => call(UserApi.getProductAlert),
    );

    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      const message = response.data;
      if (message.messageId) {
        const config = getMessageConfig(message.messageId);
        yield put(fetchProductAlertSuccess({ message, config }));
      }
    } else {
      yield put(fetchProductAlertFailure(response.data));
    }
  } catch (e) {
    yield put(fetchProductAlertFailure(e));
  }
}

export const PRODUCT_ALERT_CONFIG_STORAGE_KEY = "PRODUCT_ALERT_CONFIG";
export const getMessageConfig = (id: string): ProductAlertConfig => {
  const storedConfig =
    localStorage.getItem(PRODUCT_ALERT_CONFIG_STORAGE_KEY) || "{}";
  const alertConfig: Record<string, ProductAlertConfig> =
    JSON.parse(storedConfig);
  if (id in alertConfig) {
    return alertConfig[id];
  }
  return {
    snoozeTill: new Date(),
    dismissed: false,
  };
};

export const setMessageConfig = (id: string, config: ProductAlertConfig) => {
  const storedConfig =
    localStorage.getItem(PRODUCT_ALERT_CONFIG_STORAGE_KEY) || "{}";
  const alertConfig: Record<string, ProductAlertConfig> =
    JSON.parse(storedConfig);

  const updatedConfig: Record<string, ProductAlertConfig> = {
    ...alertConfig,
    [id]: config,
  };

  localStorage.setItem(
    PRODUCT_ALERT_CONFIG_STORAGE_KEY,
    JSON.stringify(updatedConfig),
  );
};
