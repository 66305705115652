/* eslint-disable @typescript-eslint/no-var-requires */
// Leaving this require here. Importing causes type mismatches which have not been resolved by including the typings or any other means. Ref: https://github.com/remix-run/history/issues/802
// eslint-disable-next-line @typescript-eslint/no-var-requires
let _createHistory = require("history").createMemoryHistory;
import type { History } from "history";
let createHistory:any

if (typeof document !== 'undefined') {
    createHistory = require("history").createBrowserHistory;
} else {
    createHistory = require("history").createMemoryHistory;
}

const history: History<AppsmithLocationState> = createHistory();
export default history;

export enum NavigationMethod {
  CommandClick = "CommandClick",
  EntityExplorer = "EntityExplorer",
  Omnibar = "Omnibar",
  Debugger = "Debugger",
  CanvasClick = "CanvasClick",
  ActionBackButton = "ActionBackButton",
  ContextSwitching = "ContextSwitching",
  AppSidebar = "AppSidebar",
  AppNavigation = "AppNavigation",
  PackageSidebar = "PackageSidebar",
  SegmentControl = "SegmentControl",
  EditorTabs = "EditorTabs",
  WorkflowSidebar = "WorkflowSidebar",
}

export interface AppsmithLocationState {
  invokedBy?: NavigationMethod;
}
